import { Region } from '@getpopsure/public-models';
import { ArrowRightIcon } from '@popsure/dirty-swan';
import classNames from 'classnames';
import Error from 'components/error';
import LoadingSpinner from 'components/loadingSpinner';
import routes from 'constants/routes';
import { FinalStep as ExpatDeFinalStep } from 'features/expat/components/FinalStep';
import { FinalStep as ExpatEuFinalStep } from 'features/expatEu/components/FinalStep/FinalStep';
import { FinalStep as ExpatLongTermFinalStep } from 'features/expatLongTerm/components/FinalStep/FinalStep';
import { FinalStep as ExpatEsFinalStep } from 'features/expatSpain/components/finalStep';
import {
  EmptyState,
  PolicyCard,
  SectionHeader,
} from 'features/policiesDashboard/components';
import { AdditionalCoverageCard } from 'features/policiesDashboard/components/AdditionalCoverageCard/AdditionalCoverageCard';
import { CheckoutConfirmation } from 'features/policiesDashboard/components/CheckoutConfirmation';
import { usePolicyModal } from 'features/policiesDashboard/hooks/usePolicyModal';
import { FormattedPolicyListItem } from 'features/policiesDashboard/utils/formatPoliciesList';
import { AdditionalCoverageItem } from 'features/policiesDashboard/utils/getAdditionalCoverage';
import { ReferralCodeGenerator } from 'features/referralEngine';
import { TaskEngine } from 'features/taskEngine';
import { useQueryParamValue } from 'hooks/useQueryParamValue';
import { ValidVerticalUrlParams } from 'models/insurances/types';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { getFeatureByRegion } from 'routeLocales';
import { useSafeTranslation } from 'shared/i18n';

import allPoliciesIcon from '../../assets/all-policies.svg';
import styles from './PolicyDashboard.module.scss';

interface PoliciesDashboardViewProps {
  additionalCoverageItems: AdditionalCoverageItem[];
  error?: string;
  isLoading?: boolean;
  formattedPolicies?: FormattedPolicyListItem[];
  region?: Region;
  showReferralGenerator?: boolean;
}

export const PoliciesDashboardView = ({
  additionalCoverageItems,
  error,
  isLoading,
  formattedPolicies = [],
  region,
  showReferralGenerator,
}: PoliciesDashboardViewProps) => {
  const { t } = useSafeTranslation();
  const hasPolicies = !!formattedPolicies?.length;
  const mainPolicyId = useQueryParamValue('mainPolicyId');
  const signupSuccess = useQueryParamValue('signupSuccess');
  const [showExpired, setShowExpired] = useState(false);
  const activePolicies = formattedPolicies.filter(({ expired }) => !expired);
  const expiredPolicies = formattedPolicies.filter(({ expired }) => !!expired);
  const hasAdditionalCoverage = additionalCoverageItems.length > 0;
  const hasAccessToAdditionalCoverage = region
    ? getFeatureByRegion(region)('additionalCoverage')
    : false;

  const history = useHistory();
  const { openModal, PolicyModal } = usePolicyModal();
  const signupSuccessParam = useQueryParamValue(
    'signupSuccess'
  ) as ValidVerticalUrlParams;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (mainPolicyId && signupSuccess === 'expat') {
    return <ExpatDeFinalStep mainPolicyId={mainPolicyId} />;
  }

  if (mainPolicyId && signupSuccess === 'expatSpain') {
    return <ExpatEsFinalStep mainPolicyId={mainPolicyId} />;
  }

  if (mainPolicyId && signupSuccess === 'expatEu') {
    return <ExpatEuFinalStep mainPolicyId={mainPolicyId} />;
  }

  if (mainPolicyId && signupSuccess === 'expatLongTerm') {
    return <ExpatLongTermFinalStep mainPolicyId={mainPolicyId} />;
  }

  return (
    <div
      className={classNames('wmx12 mb80 mx-auto', styles.container)}
      data-testid="policies-dashboard"
    >
      {signupSuccessParam && <CheckoutConfirmation />}

      <div className="p-body">
        {hasPolicies ? (
          <>
            <div className="mb40 d-flex ai-center jc-between">
              <h1 className="p-h1">
                {t('policies.dashboard.title', 'Your coverage')}
              </h1>
              {showReferralGenerator && (
                <span className={styles.referFriendButton}>
                  <ReferralCodeGenerator medium="dashboard" />
                </span>
              )}
            </div>

            <TaskEngine
              options={{
                screen: { title: t('taskEngine.container.title', 'Tasks') },
                onTaskClick: (task) => {
                  if (
                    task.description.type === 'GENERIC' &&
                    task.description.attributes?.claimId
                  ) {
                    history.push(
                      `/claims/${task.description.attributes?.claimId}`
                    );
                  }
                },
              }}
            />

            <SectionHeader
              buttonLabel={
                showExpired
                  ? t(
                      'policies.dashboard.showActivePolicies',
                      'Show active policies'
                    )
                  : t(
                      'policies.dashboard.showExpiredPolicies',
                      'Show expired policies'
                    )
              }
              className="mb16"
              {...(!expiredPolicies.length
                ? {}
                : {
                    onClick: () => setShowExpired(!showExpired),
                  })}
            >
              {t('policies.dashboard.policies.title', 'Policies')}
            </SectionHeader>

            <div className="d-flex f-wrap gap16 mb16">
              {showExpired
                ? expiredPolicies.map(
                    ({ attributes, content, id, status, type, action }) => {
                      return (
                        <PolicyCard
                          attributes={attributes}
                          key={id}
                          content={content}
                          type={type}
                          status={status}
                          {...(action && {
                            to: `${routes.me.policies.path}/${id}`,
                          })}
                        />
                      );
                    }
                  )
                : activePolicies.map(
                    ({
                      action,
                      attributes,
                      content,
                      id,
                      providerName,
                      status,
                      type,
                    }) => (
                      <PolicyCard
                        attributes={attributes}
                        key={id}
                        content={content}
                        type={type}
                        providerName={providerName}
                        status={status}
                        {...(action && {
                          to: `${routes.me.policies.path}/${id}`,
                        })}
                      />
                    )
                  )}
            </div>
          </>
        ) : (
          <EmptyState showRecommendation={hasAdditionalCoverage} />
        )}

        {hasAccessToAdditionalCoverage && (
          <>
            <SectionHeader className="mt56 mb16">
              {t(
                'policies.dashboard.additionalCoverage.title',
                'Explore more policies'
              )}
            </SectionHeader>
            <div className="bg-white br8">
              <div
                className={classNames('d-grid gap8 p16', styles.cardGrid, {
                  [styles.cardGridFullWidth]: !hasAdditionalCoverage,
                })}
              >
                {additionalCoverageItems.map((item) => (
                  <AdditionalCoverageCard
                    {...item}
                    onClick={() => openModal(item.type, region)}
                    key={item.type}
                  />
                ))}

                <AdditionalCoverageCard
                  borderedImage
                  image={allPoliciesIcon}
                  to={routes.me.policies.list.path}
                  title={
                    <div className="d-flex ai-center">
                      {t('policies.dashboard.seeAllProducts', 'All policies')}

                      <ArrowRightIcon size={20} />
                    </div>
                  }
                />
              </div>
            </div>
          </>
        )}
      </div>
      <PolicyModal />
    </div>
  );
};
