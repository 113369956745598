import { website } from '@getpopsure/private-constants';
import {
  getRoutes,
  removeAnswers,
  RemoveAnswerType,
  Rule,
  stateManagerHelper,
} from '@getpopsure/qnr-framework';
import { ClaimsAction } from 'constants/actions';
import routes from 'constants/routes';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  generatePath,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router';
import { AppState } from 'reducers';
import { TFunction, useSafeTranslation } from 'shared/i18n';
import {
  SignupQuestionnaire,
  SignupQuestionnaireType,
} from 'SignupQuestionnaire';

import { submitClaim } from './actions';
import { ClaimSubmitted } from './components/ClaimSubmitted';
import { LegalSubmitClaims } from './models';

export type ClaimGroupIds = 'questionnaire';

export const LegalClaimsComponents = {
  SUBMITTED: ClaimSubmitted,
} as const;

export type ClaimQuestionnaire = SignupQuestionnaireType<
  LegalSubmitClaims,
  ClaimGroupIds,
  typeof LegalClaimsComponents
>;

export const getTranslatedQuestionnaire = (
  t: TFunction,
  navigate: (path: string) => void,
  policyId: string
): ClaimQuestionnaire => [
  {
    id: 'claimNumber',
    required: true,
    type: 'INPUT',
    groupId: 'questionnaire',
    props: {
      placeholder: 'S-23-00000000',
      label: ' ',
    },
    screen: {
      question: t(
        'legal.qnr.claims.submitClaim.claimNumber.title',
        'What is your claim number?'
      ),
      description: 'It starts with S- followed by a series of numbers.',
      additionalInfo: {
        title: t(
          'legal.qnr.claims.submitClaim.claimNumber.additionalInfo.title',
          'Where to find your claim number?'
        ),
        description: t(
          'legal.qnr.claims.submitClaim.claimNumber.additionalInfo.v2.description',
          'Roland provides the claim number (Schadennummer) via email before the initial phone consultation.\n\n The claim number is required to process your claim.'
        ),
      },
      skipDetails: {
        title: t(
          'legal.qnr.claims.submitClaim.claimNumber.skipDetails.title',
          "I don't have it"
        ),
        skipQuestion: { value: '' },
        path: '',
      },
    },
  },
  {
    id: 'noClaimNumberBlocker',
    type: 'BLOCKER',
    props: {
      iconType: 'SHIELD',
      title: t(
        'legal.qnr.claims.submitClaim.noClaimNumberBlocker.v2.title',
        'Getting your claim number'
      ),

      description: t(
        'legal.qnr.claims.submitClaim.noClaimNumberBlocker.v2.description',
        'If you haven’t booked a phone consultation, you can do this here. You’ll receive your claim number afterwards.\n\nIf you’ve lost your claim number, you can reach out to us.'
      ),
      buttonProps: [
        {
          type: 'button',
          onClick: () => {
            navigate(
              generatePath(routes.claims.legal.bookConsultation.path, {
                policyId,
              })
            );
          },
          caption: t(
            'legal.qnr.claims.submitClaim.noClaimNumberBlocker.button.cta1',
            'Book a consultation'
          ),
          variant: 'PRIMARY',
        },
        {
          type: 'href',
          href: website.support,
          caption: t(
            'legal.qnr.claims.submitClaim.noClaimNumberBlocker.button.cta2',
            'Contact us'
          ),
          variant: 'SECONDARY',
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'questionnaire',
  },
  {
    id: 'description',
    required: true,
    type: 'TEXT',
    props: {
      placeholder: t(
        'legal.qnr.claims.submitClaim.description.placeholder',
        'E.g. my work contract was unjustly terminated after a dispute. I want to understand my options and potentially if I have a legal case.'
      ),
      maxLength: 10_000,
    },
    screen: {
      question: t(
        'legal.qnr.claims.submitClaim.description.title',
        'Describe your legal matter'
      ),
      additionalInfo: {
        title: t(
          'legal.qnr.claims.submitClaim.description.additionalInfo.title',
          'Describing your legal case'
        ),
        description: t(
          'legal.qnr.claims.submitClaim.description.additionalInfo.description',
          'Help us understand your legal case, including what happened and who is involved.'
        ),
      },
    },
    groupId: 'questionnaire',
  },
  {
    id: 'processing',
    required: true,
    type: 'PROCESSING',
    props: {
      textList: [
        t('legal.qnr.claims.processing.loadingText', 'Processing claim...'),
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'questionnaire',
  },
  {
    id: 'submitted',
    required: true,
    type: 'SUBMITTED',
    props: {},
    screen: {
      layout: 'Standalone',
    },
    groupId: 'questionnaire',
  },
];

const rules: Rule<LegalSubmitClaims>[] = [
  {
    id: 'claimNumber',
    if: {
      op: 'equals',
      variable: { type: 'static', value: '' },
    },
    then: {
      goTo: 'noClaimNumberBlocker',
    },
  },
];

const removeProcessing: RemoveAnswerType<LegalSubmitClaims> = {
  op: 'always',
  questions: ['processing', 'submitted'],
};

const removeAnswersLogic: Partial<
  Record<keyof LegalSubmitClaims, RemoveAnswerType<LegalSubmitClaims>>
> = {
  claimNumber: removeProcessing,
  description: removeProcessing,
};

export const storeLegalClaimsAnswers = (
  answer: Partial<LegalSubmitClaims>
): ClaimsAction => ({
  type: 'STORE_LEGAL_SUBMIT_CLAIM',
  legalSubmitClaimInfo: answer,
});

export const flushLegalClaimsAnswers = (): ClaimsAction => ({
  type: 'FLUSH_LEGAL_SUBMIT_CLAIM',
});

export const SubmitClaim = () => {
  const questionnaireAnswers =
    useSelector((state: AppState) => state.claims.legalSubmitClaimInfo) || {};
  const { policyId } = useParams<{ policyId: string }>();
  const { url } = useRouteMatch();
  const dispatch = useDispatch();

  const history = useHistory();

  const { t } = useSafeTranslation();

  const flushAnswers = useCallback(() => {
    dispatch(flushLegalClaimsAnswers());
  }, [dispatch]);

  useEffect(() => {
    return flushAnswers;
  }, [policyId, dispatch, flushAnswers]);

  const onAnswer = <QuestionId extends keyof LegalSubmitClaims>(
    questionId: QuestionId,
    answer: LegalSubmitClaims[QuestionId]
  ) => {
    const answersToRemove = stateManagerHelper(
      removeAnswersLogic,
      questionnaire.components,
      questionnaireAnswers,
      questionnaire.rules
    ).getAnswersToRemove(questionId, answer);

    const nextState = removeAnswers(
      {
        ...questionnaireAnswers,
        [questionId]: answer,
      },
      answersToRemove
    );

    dispatch(storeLegalClaimsAnswers(nextState));

    if (
      questionId === 'description' &&
      answer &&
      !questionnaireAnswers.submitted
    ) {
      dispatch(submitClaim(policyId, nextState));
    }
  };

  const questions = getTranslatedQuestionnaire(
    t,
    (path) => history.push(path),
    policyId
  );

  const questionnaire = {
    components: questions,
    routes: getRoutes(questions, url),
    rules,
  };

  return (
    <Switch>
      <Route path={routes.claims.legal.submitClaim.questionnaire.path}>
        <SignupQuestionnaire
          questionnaireAnswers={questionnaireAnswers}
          questionnaire={questionnaire}
          onAnswer={onAnswer}
          configuration={{
            components: LegalClaimsComponents,
          }}
          basePath={url}
          questionId="claimNumber"
          featureName="LegalClaimSubmitClaim"
        />
      </Route>
    </Switch>
  );
};
