import { DownloadIcon, FileTextIcon } from '@popsure/dirty-swan';
import classNames from 'classnames';
import {
  type CheckoutDocument,
  checkoutDocumentCategoryDisplayName,
} from 'features/checkoutDocuments/models';
import { useSafeTranslation } from 'shared/i18n';

import styles from './styles.module.scss';

type CheckoutDocumentsProps = {
  documents: CheckoutDocument[];
};

const CheckoutDocuments = ({ documents }: CheckoutDocumentsProps) => {
  const { t } = useSafeTranslation();
  return (
    <div className="mt48 bg-white wmx7 w100">
      <h2 className="p-h2">
        {t('paymentScreen.documents.title', 'Documents')}
      </h2>
      <div
        className={classNames(
          'mt16 d-flex fd-column r-gap8',
          styles.documentsWrapper
        )}
      >
        {documents.map((document) => (
          <a
            href={document.url}
            style={{ textDecoration: 'none', display: 'block' }}
            target="_blank"
            rel="noopener noreferrer"
            key={document.id}
          >
            <div className={classNames('py16', styles.container)}>
              <FileTextIcon
                size={24}
                color="grey-500"
                noMargin
                className="ml16 mr16 ws1"
              />
              <div className={`p-p ${styles.title}`}>
                {checkoutDocumentCategoryDisplayName(t)[document.category]}
              </div>

              <DownloadIcon
                className={styles.icon}
                color="primary-500"
                size={24}
                noMargin
              />
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default CheckoutDocuments;
