import { Button, Input } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { ChangeEvent, FormEvent, useState } from 'react';
import { TFunction, useSafeTranslation } from 'shared/i18n';

import styles from './style.module.scss';

interface ReferralCodeFormProps {
  onSubmitCode(code: string): void;
  loading?: boolean;
  error?: string;
  validReferrerCode?: string;
}

const getErrorMessage = (t: TFunction, error?: string): string | undefined => {
  if (!error) return;

  if (error === 'NEW_CUSTOMERS_ONLY')
    return t(
      'referralengine.verifymodal.error.newCustomersOnly',
      'This code is only valid for new customers'
    );

  if (error === 'NOT_VALID')
    return t(
      'referralengine.verifymodal.error.notValid',
      'This code is not valid'
    );

  return t(
    'referralengine.verifymodal.error.default',
    'This code is not valid'
  );
};

export const ReferralCodeForm = ({
  onSubmitCode,
  loading,
  error,
  validReferrerCode,
}: ReferralCodeFormProps) => {
  const { t } = useSafeTranslation();
  const [code, setCode] = useState<string>('');

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) =>
    setCode(e.target.value);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (code) {
      onSubmitCode(code);
    }
  };
  return (
    <form onSubmit={handleSubmit} className="w100 wmx7 mt24">
      <h2 className="p-h2 mb24">
        {t('referralengine.reward.title', 'Rewards')}
      </h2>
      <div className={styles.container}>
        <div className={styles.inputWrapper}>
          <Input
            placeholder={t('referralengine.reward.placeholder', 'Promo code')}
            error={getErrorMessage(t, error)}
            value={code}
            onChange={handleInputChange}
            className={styles.input}
          />
          {validReferrerCode && (
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={styles.inputCheckmark}
            >
              <circle
                cx="12"
                cy="12"
                r="10"
                fill="#85DCB4"
                stroke="#85DCB4"
                strokeWidth="2"
              />
              <path
                d="M8.25 12L10.9167 14.5L16.25 9.5"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </div>

        <Button
          type="submit"
          disabled={loading || !validReferrerCode}
          className={classNames('bg-purple-50 tc-purple-500', styles.button)}
        >
          {t('referralengine.reward.submit', 'Apply')}
        </Button>
      </div>
    </form>
  );
};
