import { Checkbox, InformationBox } from '@popsure/dirty-swan';
import { PaymentElement, useElements } from '@stripe/react-stripe-js';
import { StripePaymentElementChangeEvent } from '@stripe/stripe-js';
import { StripeElementSetupData } from 'features/paymentMethodSelector/paymentMethodSelector.models';
import { useEffect, useState } from 'react';
import { useSafeTranslation } from 'shared/i18n';

import styles from './styles.module.scss';

export interface PaymentFormProps {
  accountHolderEmail: string;
  forceMakeDefault: boolean;
  onSetupDataChange: (setupData: StripeElementSetupData | null) => void;
  onPaymentMethodTypeChange: (type: string) => void;
  hideStripePaymentElements?: boolean;
}

export const StripeElementForm = ({
  accountHolderEmail,
  forceMakeDefault,
  onSetupDataChange,
  onPaymentMethodTypeChange,
  hideStripePaymentElements = false,
}: PaymentFormProps) => {
  const { t } = useSafeTranslation();

  const [hasEnteredPaymentInfo, setHasEnteredPaymentInfo] = useState(false);
  const [makeDefault, setMakeDefault] = useState<boolean>(forceMakeDefault);

  const elements = useElements();

  useEffect(() => {
    let setupData: StripeElementSetupData | null;

    if (hasEnteredPaymentInfo) {
      setupData = { makeDefault };
    } else {
      setupData = null;
    }

    onSetupDataChange(setupData);
  }, [
    elements,
    hasEnteredPaymentInfo,
    forceMakeDefault,
    makeDefault,
    onSetupDataChange,
  ]);

  const onPaymentElementChange = ({
    complete,
    value,
  }: StripePaymentElementChangeEvent) => {
    setHasEnteredPaymentInfo(complete);
    onPaymentMethodTypeChange(value.type);
  };

  return (
    <div className="mt16" data-testid="stripe-payment-method-form">
      {!hideStripePaymentElements && (
        <PaymentElement
          onChange={onPaymentElementChange}
          options={{
            defaultValues: { billingDetails: { email: accountHolderEmail } },
          }}
        />
      )}

      <InformationBox variant="information" className="my16">
        {!forceMakeDefault ? (
          <>
            <h4 className="p-h4 pb8">
              {t(
                'paymentMethodSelector.stripeElementFormV2.makeDefault.title',
                'Default payment method'
              )}
            </h4>
            <p className="p-p">
              {t(
                'paymentMethodSelector.stripeElementFormV2.makeDefault.description',
                'This method will be used for all current and future policies. You can update it anytime in account settings.'
              )}
            </p>
            <Checkbox
              wide
              bordered={false}
              options={{
                SET_AS_DEFAULT: t(
                  'paymentMethodSelector.stripeElementFormV2.makeDefault.label',
                  'Set as default payment method'
                ),
              }}
              classNames={{ container: 'mt16', label: styles.checkboxLabel }}
              value={makeDefault ? ['SET_AS_DEFAULT'] : []}
              onChange={(values) =>
                setMakeDefault(values.includes('SET_AS_DEFAULT'))
              }
            />
          </>
        ) : (
          <p className="p-p">
            {t(
              'paymentMethodSelector.stripeElementFormV2.default.description',
              'This method will be used for all your policies. You can update it anytime in account settings.'
            )}
          </p>
        )}
      </InformationBox>
    </div>
  );
};
