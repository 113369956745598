import { Button, Checkbox, InformationBox } from '@popsure/dirty-swan';
import AnimateHeight from 'react-animate-height';
import { useSafeTranslation } from 'shared/i18n';

import { PaymentMethodSelector } from '../PaymentMethodSelector/PaymentMethodSelector';

export const PayComponent = ({
  checkboxValues,
  setCheckboxValues,
  isBeforePaypalRedirect,
  isSubmitButtonEnabled,
  error,
  purchasePolicy,
  updatePaymentMethods,
}: {
  checkboxValues: string[];
  setCheckboxValues: (values: string[]) => void;
  isBeforePaypalRedirect: boolean;
  isSubmitButtonEnabled: boolean;
  error: string | undefined;
  purchasePolicy: () => void;
  updatePaymentMethods: () => void;
}) => {
  const { t } = useSafeTranslation();

  return (
    <div>
      <div className="mt16">
        <h3 className="p-h3">
          {t('paymentScreenV2.paymentMethod.text', 'Payment method')}
        </h3>
        <PaymentMethodSelector updatePaymentMethods={updatePaymentMethods} />
      </div>

      <AnimateHeight duration={300} height={error ? 'auto' : 0}>
        <InformationBox variant="warning" className="mb16 wmx8">
          {error}
        </InformationBox>
      </AnimateHeight>

      <Checkbox
        bordered={false}
        classNames={{ container: 'my24', label: 'wmx8' }}
        onChange={setCheckboxValues}
        wide
        options={{
          hasReviewedDocuments: t(
            'paymentScreenV2.hasReviewedDocuments.checkbox.text',
            'I reviewed the insurance conditions/IPID, advice record, and licensing information.'
          ),
        }}
        value={checkboxValues}
      />
      {isBeforePaypalRedirect ? (
        <Button
          className="w100"
          disabled={!isSubmitButtonEnabled}
          onClick={purchasePolicy}
          data-cy="button-checkout"
          type="button"
        >
          {t('paymentScreenV2.button.paypal.caption', 'Continue with Paypal')}
        </Button>
      ) : (
        <Button
          className="w100"
          disabled={!isSubmitButtonEnabled}
          onClick={purchasePolicy}
          data-cy="button-checkout"
          type="button"
        >
          {t('paymentScreenV2.button.caption', 'Buy policy')}
        </Button>
      )}
      <p className="p-p--small ta-center wmx8 mt24 tc-grey-600">
        {t(
          'paymentScreenV2.disclaimer.text',
          'First payments are charged immediately, even if the policy start date is in the future. Coverage is valid only after a confirmation email is received.'
        )}
      </p>
    </div>
  );
};
