import { AlertCircleIcon, CheckIcon, FileTextIcon } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { NavButton } from 'components/NavButton';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import { StatusBadge } from 'features/claimsV2/components/statusBadge';
import documentBoxIcon from 'features/claimsV2/icons/document-box.svg';
import { getClaimTypeMapping } from 'features/claimsV2/utils';
import { TaskCard } from 'features/taskEngine/components/TaskCard';
import {
  ClaimAssessment,
  getClaimAssessmentStatusDataMapping,
} from 'models/claims';
import { imageTypeMapping } from 'models/insurances/types/mapping';
import { useLayoutEffect } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { TFunction } from 'shared/i18n';
import { capitalizeName } from 'shared/util/capitalizeName';

import styles from './ClaimAssessmentDetails.module.scss';
import { CollapsibleSection } from './components/CollapsibleSection/CollapsibleSection';
import { LinkCell } from './components/LinkCell/LinkCell';

type ClaimAssessmentDetailsViewProps = {
  assessment?: ClaimAssessment;
  t: TFunction;
};

export const ClaimAssessmentDetailsView = ({
  assessment,
  t,
}: ClaimAssessmentDetailsViewProps) => {
  const { location } = useHistory<{ from?: string }>();
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (!assessment) {
    throw new Error(
      '[CLAIM ASSESSMENTS] Trying to continue without Claim details or Policy detail'
    );
  }

  const {
    status,
    title,
    createdAt,
    diagnosis,
    doctorName,
    claims,
    insuranceType,
    insuredPerson: { name: insuredPersonName },
    notes,
    scannedDocuments = [],
    internalUploads = [],
    uploads = [],
  } = assessment;

  const sortedDocuments = [
    ...scannedDocuments,
    ...internalUploads,
    ...uploads,
  ].sort((a, b) => (dayjs(a.createdAt).isAfter(b.createdAt) ? -1 : 1));

  const sortedNotes = notes.sort((a, b) =>
    dayjs(a.createdAt).isAfter(b.createdAt) ? -1 : 1
  );

  const currentNote = sortedNotes[0];

  return (
    <div className="p-body" data-testid="claim-detail-container">
      <div className="p-body d-flex fd-column wmx10 ml-auto mr-auto">
        {location.state?.from === 'policy' ? (
          <NavButton
            tall={false}
            title={t('claims.detail.navButton.titlePolicy', 'Policy')}
          />
        ) : (
          <NavButton
            tall={false}
            title={t('claims.detail.navButton.title', 'Claims')}
            path={routes.claims.path}
          />
        )}
        <div
          className={classNames(
            'ds-card ds-card--no-dropshadow p32 gap24 d-flex',
            styles.sectionContainer
          )}
        >
          <img
            className={classNames('br8', styles.icon, styles.img)}
            src={imageTypeMapping.PRIVATE_HEALTH ?? documentBoxIcon}
            alt="insurance icon"
          />
          <div
            className={classNames(
              'd-flex fd-column jc-center r-gap8',
              styles.infoContainer
            )}
          >
            <div className={styles.info}>
              <div className="p-h2 mr16">{title}</div>
            </div>
            <div className={styles.info}>
              <div className="p-p--small tc-grey-600 fw-bold mr16">
                {dayjs(createdAt).format('DD MMM YYYY')}
              </div>
              {insuredPersonName && (
                <div className="p-p--small tc-grey-600 fw-normal">
                  {capitalizeName(insuredPersonName)}
                </div>
              )}
            </div>
          </div>
          <div
            className={classNames(
              'd-flex jc-between r-gap16',
              styles.statusContainer
            )}
          >
            <StatusBadge
              {...getClaimAssessmentStatusDataMapping({ status, t })}
            />
          </div>
        </div>
        {currentNote && (
          <div className="mt24">
            <TaskCard
              className="bg-white c-default"
              title={currentNote.title}
              subtitle={currentNote.description}
              onClick={() => {}}
            />
          </div>
        )}
        {sortedDocuments.length > 0 && (
          <CollapsibleSection
            title="Documents provided"
            initiallyOpen
            icon={
              <div className={`br-circle bg-grey-100 ${styles.iconWrapper}`}>
                <CheckIcon size={20} noMargin color="grey-500" />
              </div>
            }
          >
            <div className="d-flex py16 ml48 fd-column gap8">
              {sortedDocuments.map((document) => (
                <LinkCell href={document.url} key={document.id} isExternalLink>
                  <div className="d-flex c-gap8 ai-center">
                    <FileTextIcon size={20} noMargin color="grey-500" />
                    <p className="p-p">
                      {document.friendlyName} (
                      {dayjs(document.createdAt).format('D MMM YYYY')})
                    </p>
                  </div>
                </LinkCell>
              ))}
            </div>
          </CollapsibleSection>
        )}
        {claims?.length && claims.length > 0 && (
          <CollapsibleSection
            title="Claims under assessment"
            initiallyOpen
            icon={
              <div className={`br-circle bg-grey-100 ${styles.iconWrapper}`}>
                <AlertCircleIcon size={20} noMargin color="grey-500" />
              </div>
            }
          >
            <div className="py16 ml48">
              {claims?.map((claim) => {
                const hasClaimTypes =
                  claim.claimTypes && claim.claimTypes.length > 0;

                const claimDisplayType = hasClaimTypes
                  ? claim.claimTypes
                      .map(({ claimType }) =>
                        getClaimTypeMapping(t, {
                          insuranceType,
                          claimType,
                        })
                      )
                      .join(', ')
                  : null;

                return (
                  <LinkCell
                    href={generatePath(routes.claims.detail.path, {
                      claimId: claim.id,
                    })}
                    key={claim.id}
                  >
                    <div>
                      <p className="p-p">
                        {claimDisplayType || 'Hospital treatment'}
                      </p>
                      <p className="p-p--small tc-grey-600">
                        {dayjs(claim.createdAt).format('DD MMM YYYY')}{' '}
                      </p>
                    </div>
                  </LinkCell>
                );
              })}
            </div>
          </CollapsibleSection>
        )}
        <CollapsibleSection
          title="Assessment has started"
          initiallyOpen
          icon={
            <div className={`br-circle bg-grey-100 ${styles.iconWrapper}`}>
              <CheckIcon size={20} noMargin color="grey-500" />
            </div>
          }
        >
          <div className="py16 ml48">
            <div
              className={`d-grid r-gap24 ${styles.assessmentStartedContent}`}
            >
              <p className="p-p tc-grey-600">Diagnosis</p>
              <p className="p-p">{diagnosis || '-'}</p>
              <p className="p-p tc-grey-600">Doctor's name</p>
              <p className="p-p">{doctorName || '-'}</p>
            </div>
          </div>
        </CollapsibleSection>
      </div>
    </div>
  );
};
