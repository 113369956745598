import { TFunction } from '@getpopsure/i18n-react';
import { website } from '@getpopsure/private-constants';
import {
  getRoutes,
  removeAnswers,
  RemoveAnswerType,
  Rule,
  stateManagerHelper,
} from '@getpopsure/qnr-framework';
import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';
import { ErrorWithAction } from 'components/ErrorWithAction';
import LoadingSpinner from 'components/loadingSpinner';
import { ClaimsAction } from 'constants/actions';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import { useLoadPolicyDetail } from 'features/expat/hooks';
import { Claim } from 'models/claims';
import { Policy } from 'models/policies';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  generatePath,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router';
import { AppState } from 'reducers';
import { useSafeTranslation } from 'shared/i18n';
import {
  SignupQuestionnaire,
  SignupQuestionnaireType,
} from 'SignupQuestionnaire';

import { ClaimSubmitted } from './components/ClaimSubmitted';
import { GettingStarted } from './components/GettingStarted';
import { PayoutDetails } from './components/PayoutDetails';
import { useSubmitExpatEuClaim } from './hooks/useSubmitExpatEuClaim';
import {
  basicClaimTypeMapping,
  ExpatEuClaim,
  premiumClaimTypeMapping,
} from './models';

export type ClaimGroupIds = 'questionnaire';

const ExpatClaimComponents = {
  INTRO: GettingStarted,
  PAYOUT_DETAILS: PayoutDetails,
  SUBMISSION: ClaimSubmitted,
} as const;

export type ClaimQuestionnaire = SignupQuestionnaireType<
  ExpatEuClaim,
  ClaimGroupIds,
  typeof ExpatClaimComponents
>;

export const getTranslatedQuestionnaire = (
  t: TFunction,
  policy: Policy,
  submitClaimRequest: () => Promise<AxiosResponse<Claim>>
): ClaimQuestionnaire => [
  {
    id: 'intro',
    required: true,
    type: 'INTRO',
    groupId: 'questionnaire',
    props: {},
    screen: {
      layout: 'Standalone',
      noMaxWidth: true,
    },
  },
  {
    id: 'eventDate',
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 10 },
        max: { op: 'subtract', type: 'years', value: 0 },
      },
    },
    screen: {
      question: t(
        'claims.expatEu.eventDate.title',
        'When did you get the medical treatment?'
      ),
      description: t(
        'claims.expatEu.eventDate.description',
        'Please enter the date you received the treatment, not the date on the invoice.'
      ),
    },
    validations: [
      {
        op: 'dateDiff',
        variable: {
          type: 'day',
          value: 1,
        },
        msg: {
          type: 'Info',
          value: t(
            'claims.expatEu.eventDate.noFutureDate.error',
            'You can’t select a date in the future. Please make sure the date is correct.'
          ),
        },
      },
    ],
    groupId: 'questionnaire',
  },
  {
    id: 'eventDateBlocker',
    type: 'BLOCKER',
    props: {
      title: t(
        'claims.expatEu.eventDate.blocker.title',
        'Medical treatment received after policy cancelation'
      ),
      iconType: 'SHIELD',
      description: t(
        'claims.expatEu.eventDate.blocker.description',
        `Your policy ended on {{endDate}}. You can only submit claims for the medical treatments received before this date.`,
        {
          endDate: dayjs(policy.attributes.activeUntil).format('DD MMM YYYY'),
        }
      ),
      buttonProps: [
        {
          type: 'href',
          href: website.support,
          caption: t(
            'claims.expatEu.eventDate.blocker.button.text',
            'Contact us'
          ),
          variant: 'SECONDARY',
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'questionnaire',
  },
  {
    id: 'claimType',
    type: 'RADIO',
    props: {
      mapValue:
        policy.attributes.planId === 'BASIC'
          ? basicClaimTypeMapping(t)
          : premiumClaimTypeMapping(t),
    },
    screen: {
      question: t('claims.expatEu.claimType.title', 'Select a claim type'),
    },
    groupId: 'questionnaire',
  },
  {
    id: 'additionalInfo',
    type: 'INPUT',
    props: {
      placeholder: t(
        'claims.expatEu.additionalInfo.placeholder',
        'E.g. contact lenses, cancer screening'
      ),
    },
    screen: {
      question: t(
        'claims.expatEu.additionalInfo.title',
        'What is this claim for?'
      ),
    },
    groupId: 'questionnaire',
  },
  {
    id: 'uploadDocuments',
    type: 'UPLOAD',
    required: true,
    props: {
      showLegalNotice: true,
    },
    screen: {
      question: t('claims.expatEu.uploadDocuments.title', 'Upload documents'),
      additionalInfo: {
        title: t(
          'claims.expatEu.uploadDocuments.infoBox.title',
          'What documents should I provide?'
        ),
        description: t(
          'claims.expatEu.uploadDocuments.infoBox.description',
          `1. Invoices\n2. Referral to a specialist or physical therapist\n3. Prescriptions with diagnosis (medications, aids, and remedies)\n\nWe accept both scanned documents and photos of documents. Make sure that **all pages of the invoice** are included, and the text is readable before submitting.`
        ),
      },
    },
    groupId: 'questionnaire',
  },
  {
    id: 'payoutDetails',
    type: 'PAYOUT_DETAILS',
    groupId: 'questionnaire',
    props: {},
  },
  {
    id: 'processing',
    required: true,
    type: 'PROCESSING',
    props: {
      requestFn: submitClaimRequest,
      textList: [
        t('claims.expatEu.processing.loadingText', 'Processing claim...'),
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'questionnaire',
  },
  {
    id: 'submitted',
    required: true,
    type: 'SUBMISSION',
    props: {},
    screen: {
      layout: 'Standalone',
    },
    groupId: 'questionnaire',
  },
];

const rules = (policy: Policy): Rule<ExpatEuClaim>[] => [
  {
    id: 'intro',
    if: () => {
      return dayjs().isAfter(dayjs(policy.attributes.activeUntil));
    },
    then: {
      goTo: 'eventDate',
    },
    else: {
      goTo: 'claimType',
    },
  },
  {
    id: 'eventDate',
    if: (answer) => {
      return dayjs(String(answer)).isAfter(
        dayjs(policy.attributes.activeUntil)
      );
    },
    then: {
      goTo: 'eventDateBlocker',
    },
    else: {
      goTo: 'claimType',
    },
  },
  {
    id: 'claimType',
    if: (answer) => {
      return answer === 'OTHER';
    },
    then: {
      goTo: 'additionalInfo',
    },
    else: {
      goTo: 'uploadDocuments',
    },
  },
  {
    id: 'uploadDocuments',
    if: (_, answers) => {
      return answers.claimType !== 'COST_PLAN';
    },
    then: {
      goTo: 'payoutDetails',
    },
    else: {
      goTo: 'processing',
    },
  },
];

const removeProcessing: RemoveAnswerType<ExpatEuClaim> = {
  op: 'always',
  questions: ['processing', 'submitted'],
};

const removeAnswersLogic: Partial<
  Record<keyof ExpatEuClaim, RemoveAnswerType<ExpatEuClaim>>
> = {
  eventDate: removeProcessing,
  additionalInfo: removeProcessing,
  claimType: removeProcessing,
  uploadDocuments: removeProcessing,
  payoutDetails: removeProcessing,
};

export const storeExpatEuClaimsAnswers = (
  answer: Partial<ExpatEuClaim>
): ClaimsAction => ({
  type: 'STORE_EXPAT_EU_CLAIM',
  expatEu: answer,
});

export const flushExpatEuClaimsAnswers = (): ClaimsAction => ({
  type: 'FLUSH_EXPAT_EU_CLAIM',
});

export const ExpatEuClaims = () => {
  const questionnaireAnswers =
    useSelector((state: AppState) => state.claims.expatEu) || {};
  const { policyId } = useParams<{ policyId: string }>();
  const { url } = useRouteMatch();
  const dispatch = useDispatch();

  const { t } = useSafeTranslation();

  const { submitClaimRequest } = useSubmitExpatEuClaim(
    questionnaireAnswers,
    policyId
  );

  const history = useHistory();

  const {
    loading: policyLoading,
    policy,
    error,
  } = useLoadPolicyDetail(policyId);

  const flushAnswers = useCallback(() => {
    dispatch(flushExpatEuClaimsAnswers());
  }, [dispatch]);

  useEffect(() => {
    return flushAnswers;
  }, [policyId, dispatch, flushAnswers]);

  const handlePolicyError = () => {
    const policyDetailsPage = generatePath(routes.me.policies.path, {
      policyId,
    });
    history.push(policyDetailsPage);
  };

  if (error) {
    Sentry.captureException(
      `[Expat Eu Health] Failed to fetch policy. Claim process not initiated.`
    );
    return (
      <ErrorWithAction
        title={t(
          'claims.expatClaims.startClaimError.title',
          'Something went wrong'
        )}
        description={t(
          'claims.expatClaims.startClaimError.description',
          'Sorry, something didn’t work as it should. Some information needed to submit the claim are missing.'
        )}
        cta={{
          title: t(
            'claims.expatClaims.startClaimError.cta',
            'Go back to your policies'
          ),
          onClick: handlePolicyError,
        }}
      />
    );
  }

  if (policyLoading === true || !policy) {
    return <LoadingSpinner />;
  }

  const onAnswer = <QuestionId extends keyof ExpatEuClaim>(
    questionId: QuestionId,
    answer: ExpatEuClaim[QuestionId]
  ) => {
    const answersToRemove = stateManagerHelper(
      removeAnswersLogic,
      questionnaire.components,
      questionnaireAnswers,
      questionnaire.rules
    ).getAnswersToRemove(questionId, answer);

    const nextState = removeAnswers(
      {
        ...questionnaireAnswers,
        [questionId]: answer,
      },
      answersToRemove
    );

    dispatch(storeExpatEuClaimsAnswers(nextState));
  };

  const questions = getTranslatedQuestionnaire(t, policy, submitClaimRequest);

  const questionnaire = {
    components: questions,
    routes: getRoutes(questions, url),
    rules: rules(policy),
  };

  return (
    <Switch>
      <Route path={routes.claims.expatEu.questionnaire.path}>
        <SignupQuestionnaire
          questionnaireAnswers={questionnaireAnswers}
          questionnaire={questionnaire}
          onAnswer={onAnswer}
          configuration={{
            components: ExpatClaimComponents,
          }}
          basePath={url}
          questionId="intro"
          featureName="ExpatClaim"
        />
      </Route>
    </Switch>
  );
};
