import { capitalizeName } from '@getpopsure/public-utility';
import Session from '@getpopsure/session';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import {
  getGenderMapping,
  getInclusiveMembersMapping,
  getPastClaimsMapping,
  getQuestionsMapping,
  InclusiveMember,
  isAddress,
  isGender,
  isInclusiveMembers,
  isName,
  isPastClaims,
  QuestionId,
  QuestionnaireValueTypeMapping,
} from 'models/liability';
import { TFunction } from 'shared/i18n';

export const retrieveNextPagePath = (
  answer: QuestionnaireValueTypeMapping[QuestionId]
): string => {
  switch (answer.questionId) {
    case 'inclusiveMembers':
      return routes.policies.liability.questionnaire.numberOfPreviousClaims
        .path;

    case 'numberOfPreviousClaims': {
      if (answer.value === 'TWO_OR_MORE') {
        return routes.policies.liability.blocker.path;
      }

      return routes.policies.liability.questionnaire.canceledByAnotherProvider
        .path;
    }

    case 'canceledByAnotherProvider': {
      const { isAuthenticated } = Session;

      if (answer.value === true) {
        return routes.policies.liability.previousCancelationBlocker.path;
      }

      if (isAuthenticated) {
        return routes.policies.liability.account.path;
      }

      return routes.policies.liability.email.path;
    }

    case 'email':
      return routes.policies.liability.questionnaire.name.path;

    case 'name':
      return routes.policies.liability.questionnaire.dateOfBirth.path;

    case 'dateOfBirth':
      return routes.policies.liability.questionnaire.gender.path;

    case 'gender':
      return routes.policies.liability.questionnaire.currentlyLivingInGermany
        .path;

    case 'currentlyLivingInGermany':
      if (answer.value === true) {
        return routes.policies.liability.questionnaire.address.path;
      }
      return routes.policies.liability.questionnaire.relocationDate.path;

    case 'address':
      return routes.policies.liability.questionnaire.startDate.path;

    case 'relocationDate':
      return routes.policies.liability.questionnaire.startDate.path;

    case 'startDate':
      return routes.policies.liability.review.path;

    case 'quote':
      return routes.policies.liability.checkout.path;

    case 'checkoutInfo':
      return routes.policies.liability.checkout.path;

    case 'account':
      return routes.policies.liability.account.path;
  }
};

export const formatAnswers =
  (t: TFunction) =>
  (id: QuestionId, answer: unknown): string | null => {
    const notAvailableAnswer = t(
      'page.liability.review.answerValues.notProvided',
      'Not provided'
    );

    if (typeof answer === 'boolean') {
      return answer
        ? t('page.liability.review.answerValues.yes', 'Yes')
        : t('page.liability.review.answerValues.no', 'No');
    }

    if (id === 'inclusiveMembers' && isInclusiveMembers(answer)) {
      return formatInclusiveMembers(answer, t);
    }

    if (id === 'numberOfPreviousClaims' && isPastClaims(answer)) {
      return getPastClaimsMapping(t)[answer];
    }

    if (id === 'name' && isName(answer)) {
      return capitalizeName(answer);
    }

    if (id === 'relocationDate' || id === 'startDate' || id === 'dateOfBirth') {
      return typeof answer === 'string'
        ? dayjs(answer).format('DD MMM YYYY')
        : null;
    }

    if (id === 'address') {
      if (!answer) {
        return notAvailableAnswer;
      }

      if (isAddress(answer)) {
        const { street, houseNumber, postcode, city, country } = answer;
        return `${street} ${houseNumber}, ${postcode} ${city} ${country}`;
      }
    }

    if (id === 'gender' && isGender(answer)) {
      return getGenderMapping(t)[answer];
    }

    if (typeof answer === 'string') {
      return answer;
    }

    return null;
  };

export const validMember = (input: string): boolean =>
  input === 'PARTNER' || input === 'KIDS' || input === 'PARENTS';

export const validMembersList = (members: string[]): boolean => {
  const validatedMembers = members?.filter((item) => validMember(item));
  return Boolean(validatedMembers.length === members.length);
};

export const createLabels =
  (t: TFunction) =>
  (questionIds: QuestionId[]): { [K in QuestionId]: string } =>
    questionIds.reduce((obj, item) => {
      if (!getQuestionsMapping(t)[item]) {
        return { ...obj };
      }
      return {
        ...obj,
        [item]: getQuestionsMapping(t)[item],
      };
    }, {} as { [K in QuestionId]: string });

export const formatInclusiveMembers = (
  inclusiveMembers: InclusiveMember[],
  t: TFunction
): string =>
  inclusiveMembers.reduce((value, current, idx) => {
    const mappedCurrentValue = getInclusiveMembersMapping(t)[current];

    if (inclusiveMembers && idx === inclusiveMembers.length - 1) {
      // eslint-disable-next-line no-return-assign, no-param-reassign
      return (value += mappedCurrentValue);
    }
    // eslint-disable-next-line no-return-assign, no-param-reassign
    return (value += `${mappedCurrentValue}, `);
  }, '');
