import { trackQuoteScreenReached } from '@getpopsure/analytics';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import { Button } from '@popsure/dirty-swan';
import classNames from 'classnames';
import dayjs from 'dayjs';
import {
  PetHealth,
  Quote,
  ZSubmittablePetHealthQuestionnaire,
} from 'features/petHealth/models';
import { getPrice, getPrices } from 'features/petHealth/models/priceGroups';
import { useEffect, useState } from 'react';
import * as QP from 'SignupQuestionnaire/components/QuotePage';
import { SegmentedControl } from 'SignupQuestionnaire/components/QuotePage/SegmentedControl';
import { CoveredItem } from 'SignupQuestionnaire/components/QuotePage/WhatsCoveredSection/WhatsCoveredSection';

import styles from './QuotePage.module.scss';
import { SelectCard } from './SelectCard';

type QuotePageProps = {
  config: Omit<
    QP.QuotePageConfiguration<PetHealth['quote']>,
    'coveredSection'
  > & {
    coveredSection: {
      title: string;
      layout?: {
        compact?: boolean;
        cards?: boolean;
      };
      items: CoveredItem[];
    }[];
  };
} & QuestionnaireFormProps<PetHealth, PetHealth['quote']>;

export const QuotePage = ({
  questionnaireAnswers,
  onSubmitValue,
  config,
}: QuotePageProps) => {
  const result =
    ZSubmittablePetHealthQuestionnaire.safeParse(questionnaireAnswers);

  if (!result.success) {
    throw new Error(
      `[PET_HEALTH - Quote]: Questionnaire answer validation errors: ${result.error.toString()}`
    );
  }

  const { data } = result;

  const [state, setState] = useState<Quote>(() => {
    if (questionnaireAnswers.quote) {
      return questionnaireAnswers.quote;
    }
    return {
      plan: 'SURGERY',
      isDeductible: true,
      price: getPrice(data, 'SURGERY', true),
    };
  });

  const petYoungerThanFour = dayjs(questionnaireAnswers.dateOfBirth).isAfter(
    dayjs().subtract(4, 'year')
  );

  const [surgeryPrice, plusPrice] = getPrices(data, state.isDeductible);

  useEffect(() => {
    const [surgeryPriceAlternative, standardPriceAlternative] = getPrices(
      data,
      !state.isDeductible
    );
    trackQuoteScreenReached({
      vertical: 'PET_HEALTH',
      quotes: [
        {
          name: 'surgery',
          price: surgeryPrice,
          deductible: state.isDeductible,
        },
        {
          name: 'standard',
          price: plusPrice,
          deductible: state.isDeductible,
        },

        {
          name: 'surgery',
          price: surgeryPriceAlternative,
          deductible: !state.isDeductible,
        },
        {
          name: 'standard',
          price: standardPriceAlternative,
          deductible: !state.isDeductible,
        },
      ],
      parameters: {
        type: questionnaireAnswers.species,
        deductible: state.isDeductible,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <QP.Wrapper>
      <div className="d-flex fd-column ai-center jc-center bg-grey-200 py72">
        <QP.Header title={config.title} headerImage={config.headerImage} />
        <div className={`w100 br8 mt16 ${styles.container}`}>
          <div
            className={classNames(
              'w100 d-flex fd-row ai-center jc-center ai-center'
            )}
          >
            <div
              className={classNames(
                'ws10 jc-between d-flex fd-row gap16 ',
                styles.contentContainer
              )}
            >
              <SelectCard
                key="surgeryPriceCard"
                title="Surgery"
                price={englishFormattedEuroCurrency(surgeryPrice)}
                description="Only medically required operations"
                isChecked={state.plan === 'SURGERY'}
                onChange={() =>
                  setState((prevState) => ({
                    ...prevState,
                    plan: 'SURGERY',
                    price: getPrice(data, 'SURGERY', prevState.isDeductible),
                  }))
                }
              />
              <SelectCard
                key="plusPriceCard"
                title="Plus"
                price={englishFormattedEuroCurrency(plusPrice)}
                description="Also covers non-surgical and preventative vet treatments"
                isChecked={state.plan === 'PLUS'}
                onChange={() =>
                  setState((prevState) => ({
                    ...prevState,
                    plan: 'PLUS',
                    price: getPrice(data, 'PLUS', prevState.isDeductible),
                  }))
                }
              />
            </div>
          </div>
          {petYoungerThanFour ? (
            <div className="p-container bg-white d-flex fd-column wmx10 jc-center ai-center gap16 mt32 px32 py24 br8">
              <div className="p-h3 tc-grey-900">
                What percentage of the vet bill should we cover?
              </div>
              <div className="p-p ta-center fw-normal tc-grey-700 mb8">
                If you select 100%, your bills will be paid in full. If you
                select 80% and submit a claim for €2,000, you will pay €400 and
                we will pay the remaining €1,600.
              </div>
              <SegmentedControl
                key="deductible-0"
                selectedValue={state.isDeductible ? 0 : 1}
                mapValues={['80%', '100%']}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    isDeductible: value === 0,
                    price: getPrice(data, prevState.plan, value === 0),
                  }))
                }
              />
            </div>
          ) : (
            <div className="p-container bg-white d-flex fd-column wmx10 jc-center ai-center gap16 mt32 px32 py24 br8">
              <div className="p-h3 tc-grey-900">
                Your policy includes a deductible
              </div>
              <div className="p-p ta-center fw-normal tc-grey-700 mb8">
                Your policy includes a deductible. This means you have to cover
                20% of the cost of your claims. For example, if you submit a
                claim for €2,000, you will pay €400 and we will pay the
                remaining €1,600.
              </div>
            </div>
          )}
          <div className="p-container wmx9 ai-center jc-center d-flex fd-column mt48">
            <Button
              className="wmn4"
              onClick={() => {
                onSubmitValue(state);
              }}
              data-cy="quote-continue"
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
      {config.coveredSection?.map((section) => (
        <QP.WhatsCoveredSection key={section.title} {...section} />
      ))}
      {config.notCoveredSection && (
        <QP.WhatsNotCoveredSection {...config.notCoveredSection} />
      )}
      {config.ctaBox && (
        <QP.CTABox
          {...config.ctaBox}
          title={QP.replacePlaceholder(
            config.ctaBox.title,
            state.price ? englishFormattedEuroCurrency(state.price) : '-'
          )}
          onSubmit={() => {
            onSubmitValue(state);
          }}
        />
      )}
      {config.reviewBadge && (
        <div className="p-container d-flex ai-center jc-center mb56">
          <QP.ReviewBadge />
        </div>
      )}
      {config.faq && <QP.FAQ {...config.faq} />}
      {config.moreQuestionsSection && (
        <QP.MoreQuestionsSection {...config.moreQuestionsSection} />
      )}
    </QP.Wrapper>
  );
};
