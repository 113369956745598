import { GenericQuestionnaireState } from 'reducers/genericQuestionnaire';

export const insuranceTypes = [
  'BASIS_PENSION',
  'BIKE',
  'BIKE_V2',
  'CAR',
  'COMPANY_HEALTH',
  'COMPANY_LIFE',
  'COMPANY_PENSION',
  'DENTAL',
  'DENTAL',
  'DENTAL_V2',
  'DISABILITY',
  'DISABILITY_V2',
  'DOG_LIABILITY',
  'EXPAT_V2',
  'GENERIC',
  'HOUSEHOLD',
  'INCOMING',
  'INCOMING_ES',
  'INCOMING_EU',
  'INCOMING_LT',
  'JOB',
  'LEGAL',
  'LEGAL_V2',
  'LIABILITY',
  'LIFE',
  'LIFE_V2',
  'PENSION',
  'PENSION',
  'PET_HEALTH',
  'PRIVATE_HEALTH',
  'PUBLIC_HEALTH',
  'TRAVEL',
  'TRAVEL_FR',
  'TRAVEL_HEALTH_V1',
  'DOG_LIABILITY_ES',
  'BIKE_ES',
] as const;

export type InsuranceTypes = typeof insuranceTypes[number];

export const policyInfoKinds = [
  'DisabilityPolicy',
  'ExpatSpainPolicy',
  'ExpatSpainDependentPolicy',
];
export type PolicyInfoKind = typeof policyInfoKinds[number];

export const policyInfoKindMapping = (
  isDependentPolicy: boolean
): Partial<Record<InsuranceTypes, PolicyInfoKind | null>> => {
  return {
    DISABILITY_V2: 'DisabilityPolicy',
    INCOMING_EU: isDependentPolicy ? 'ExpatEuDependentPolicy' : 'ExpatEuPolicy',
  };
};

export const isValidInsuranceType = (
  value: unknown
): value is InsuranceTypes => {
  return (
    typeof value === 'string' &&
    insuranceTypes.includes(value as InsuranceTypes)
  );
};

export type ValidVerticalUrlParams =
  | keyof GenericQuestionnaireState
  | 'publicHealth'
  | 'car'
  | 'job'
  | 'liability'
  | 'BIKE_ES';
