import { TFunction, useTranslation } from '@getpopsure/i18n-react';
import {
  calculateLegalPricing,
  HIGH_DEDUCTIBLE,
  Plan,
} from '@getpopsure/legal-insurance-pricing-engine';
import { faq, typeform } from '@getpopsure/private-constants';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import { Accordion, Button, Radio } from '@popsure/dirty-swan';
import classNames from 'classnames';
import Markdown from 'components/markdown';
import MoreQuestionsSection from 'components/moreQuestions';
import ReviewBadge from 'components/reviewBadge';
import { Legal, QuoteInfo } from 'features/legal/models';
import {
  bannerColorMapping,
  imageTypeMapping,
} from 'models/insurances/types/mapping';
import { ReactNode, useState } from 'react';

import AddOnsSection from './AddOnsSection/AddOnsSection';
import { ComparisonTable } from './ComparisonTable/ComparisonTable';
import { faqs } from './content';
import advancedIcon from './icons/advanced.svg';
import basicIcon from './icons/basic.svg';
import { LegalQuotePlan } from './models';
import styles from './style.module.scss';

const legalImg = imageTypeMapping.LEGAL;

const Quote = ({
  questionnaireAnswers,
  onSubmitValue,
}: QuestionnaireFormProps<Legal>) => {
  const { t } = useTranslation();

  const [quoteCustomization, setQuoteCustomization] = useState<
    Partial<QuoteInfo>
  >({
    plan: 'BASIC',
    addons: [],
  });

  const plan = quoteCustomization.plan ?? 'BASIC';

  const { totalPremium: priceBasicPlan } = calculateLegalPricing({
    dateOfBirth: questionnaireAnswers.dateOfBirth ?? '',
    familyStatus: questionnaireAnswers?.includeSpouse ? 'FAMILY' : 'SINGLE',
    plan: 'BASIC',
    postcode: questionnaireAnswers.postcode ?? '',
    profession: questionnaireAnswers.employmentStatus ?? 'UNKNOWN_PROFESSION',
    addons: [],
  });
  const { totalPremium: priceAdvancedPlan } = calculateLegalPricing({
    dateOfBirth: questionnaireAnswers.dateOfBirth ?? '',
    familyStatus: questionnaireAnswers?.includeSpouse ? 'FAMILY' : 'SINGLE',
    plan: 'ADVANCED',
    postcode: questionnaireAnswers.postcode ?? '',
    profession: questionnaireAnswers.employmentStatus ?? 'UNKNOWN_PROFESSION',
    addons: [],
  });

  const planMapping: LegalQuotePlan = {
    BASIC: {
      shortName: t('legal.qnr.quote.basic.label', 'Basic'),
      longName: t('legal.qnr.quote.basic.longLabel', 'Basic plan'),
      icon: basicIcon,
      price: priceBasicPlan,
    },
    ADVANCED: {
      shortName: t('legal.qnr.quote.advanced.label', 'Advanced'),
      longName: t('legal.qnr.quote.advanced.longLabel', 'Advanced plan'),
      icon: advancedIcon,
      price: priceAdvancedPlan,
    },
  };

  const handleSubmitQuote = () => {
    onSubmitValue({
      ...quoteCustomization,
      price: totalPremium,
      basePremium,
      addonsPrice: addons,
      deductible: HIGH_DEDUCTIBLE,
    });
  };

  const { totalPremium, basePremium } = calculateLegalPricing({
    dateOfBirth: questionnaireAnswers.dateOfBirth ?? '',
    familyStatus: questionnaireAnswers?.includeSpouse ? 'FAMILY' : 'SINGLE',
    plan,
    postcode: questionnaireAnswers.postcode ?? '',
    profession: questionnaireAnswers.employmentStatus ?? 'UNKNOWN_PROFESSION',
    addons: quoteCustomization.addons ?? [],
  });

  const { addons } = calculateLegalPricing({
    dateOfBirth: questionnaireAnswers.dateOfBirth ?? '',
    familyStatus: questionnaireAnswers?.includeSpouse ? 'FAMILY' : 'SINGLE',
    plan,
    postcode: questionnaireAnswers.postcode ?? '',
    profession: questionnaireAnswers.employmentStatus ?? 'UNKNOWN_PROFESSION',
    addons: ['CRIMINAL', 'PROPERTY'],
  });

  return (
    <>
      <div className={classNames('bg-white', styles.quoteContainer)}>
        <header>
          <div
            className={classNames('px16', styles.headerImgWrapper)}
            style={{ backgroundColor: bannerColorMapping.LEGAL }}
          >
            <img
              src={legalImg}
              className={`${styles.headerImg}`}
              alt="scale of justice"
            />
          </div>
          <div
            className={classNames(
              'p-body d-flex fd-column ai-center',
              styles.titleContainer
            )}
          >
            <h1 className="p-h1 p--serif">
              {t('legal.qnr.quote.title', 'Choose your plan')}
            </h1>
          </div>
          <div
            className={classNames(
              'p-body d-flex jc-center mt32',
              styles.contentContainer
            )}
          >
            <Radio
              options={planRadioOptions(t, planMapping)}
              onChange={(value) =>
                setQuoteCustomization((prevState) => ({
                  ...prevState,
                  plan: value,
                }))
              }
              value={plan}
              wide
              classNames={{
                container: classNames(
                  'd-flex jc-center gap24 f-column',
                  styles.radioContainer
                ),
                label: classNames(
                  'd-flex jc-center gap8 f-column',
                  styles.radioLabel
                ),
                option: classNames('ws4', styles.radioOption),
              }}
            />
          </div>
        </header>
        <div className={classNames('pt56', styles.contentWrapper)}>
          <section className={classNames(styles.comparisonTable)}>
            <ComparisonTable showPrice planMapping={planMapping} />
          </section>
          <section
            className={classNames(
              'p-body mt56',
              classNames(styles.addOnsContainer)
            )}
          >
            <AddOnsSection
              quoteCustomization={quoteCustomization}
              setQuoteCustomization={setQuoteCustomization}
              addons={addons}
            />
          </section>

          <section className="wmx12 mx-auto py32">
            <header
              className={classNames(
                'fd-column ai-center pb24',
                styles.tableHeader
              )}
            >
              <h2 className="p-h2 p--serif">
                {t('legal.qnr.quote.faqs.title', 'Frequently asked questions')}
              </h2>
              <div className={classNames('ws2', styles.headerUnderline)} />
            </header>
            <Accordion
              renderAnswer={(answer) => (
                <Markdown
                  paragraphClassName="p-p tc-grey-600 py8"
                  openLinksInNewTab
                >
                  {answer as string}
                </Markdown>
              )}
              items={faqs(t)}
            />
          </section>

          <ReviewBadge
            className={classNames(
              'w100 d-flex jc-center gap16',
              styles.reviewContainer
            )}
          />
          <MoreQuestionsSection
            title={t(
              'legal.qnr.quote.moreQuestions.title',
              'Have more questions?'
            )}
            description={t(
              'legal.qnr.quote.moreQuestions.description',
              'Reach out to us for more information or check out our FAQ for answers to common questions about legal insurance.'
            )}
            bookACallGACategory="LEGAL_INSURANCE"
            openInNewTab
            secondaryAction={t(
              'legal.qnr.quote.secondaryAction.title',
              'Visit our FAQs'
            )}
            secondaryActionLink={faq.base}
            bookACallAction={() => {
              window.open(
                `${typeform.base}/to/f3nNrCtc?typeform-source=feather-insurance.com`
              );
            }}
          />
        </div>
      </div>
      <footer
        className={classNames(
          'w100 d-flex jc-center ai-center',
          styles.fixedPriceBar
        )}
      >
        <div
          className={classNames(
            'p-body jc-between ai-center ws12',
            styles.desktopSticky
          )}
        >
          <div className="d-flex ai-end gap8">
            <img src={planMapping[plan].icon} alt="icon of a shield" />
            <h3 className="p-h3">{planMapping[plan].longName}</h3>
          </div>
          <div className="d-flex ai-center gap16">
            <p className="p--serif p-p--small tc-primary-500">
              <span className="p-h2 tc-primary-500">
                {englishFormattedEuroCurrency(totalPremium, true)}
              </span>
              {t('legal.qnr.quote.footer.price.perMonth', '/mo')}
            </p>
            <div className={`d-flex ai-center gap16 ${styles.buttonContainer}`}>
              <Button
                onClick={handleSubmitQuote}
                type="button"
                className="ws3"
                data-cy="legal-quote-continue"
              >
                {t('legal.qnr.quote.footer.continueButton.label', 'Continue')}
              </Button>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            'p-body ai-center jc-between gap16 w100',
            styles.mobileSticky
          )}
        >
          <div className="d-flex ai-center gap16">
            <img src={planMapping[plan].icon} alt="icon of a shield" />
            <div>
              <h3 className="p-h3">{planMapping[plan].longName}</h3>
              <p className="p--serif p-p--small tc-primary-500">
                <span className="p-h2 tc-primary-500">
                  {englishFormattedEuroCurrency(totalPremium, true)}
                </span>
                {t('legal.qnr.quote.footer.price.perMonth', '/mo')}
              </p>
            </div>
          </div>
          <Button onClick={handleSubmitQuote} type="button" className="ws2">
            {t('legal.qnr.quote.continueButton.label', 'Continue')}
          </Button>
        </div>
      </footer>
    </>
  );
};

export default Quote;

type QuotePlanRadio = Record<Plan, { title: ReactNode; icon: () => ReactNode }>;

export const planRadioOptions = (
  t: TFunction,
  planMapping: LegalQuotePlan
): QuotePlanRadio => {
  return Object.keys(planMapping).reduce<QuotePlanRadio>(
    (options, currentPlan) => {
      return {
        ...options,
        [currentPlan]: {
          icon: () => (
            <img src={planMapping[currentPlan as Plan].icon} alt="" />
          ),
          title: (
            <>
              <p className="p-h2 ta-center">
                {planMapping[currentPlan as Plan].shortName}
              </p>
              <p className="p--serif p-p--small tc-primary-500 ta-center">
                <span className="p-h1 tc-primary-500">
                  {englishFormattedEuroCurrency(
                    planMapping[currentPlan as Plan].price,
                    true
                  )}
                </span>
                {t('legal.qnr.footer.price.perMonth', '/mo')}
              </p>
            </>
          ),
        },
      };
    },
    {} as QuotePlanRadio
  );
};
