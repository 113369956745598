import { language, Locale } from '@getpopsure/i18n-react';
import { AxiosInstance } from 'axios';
import { InsuranceTypes } from 'models/insurances/types';
import { validateLocale } from 'shared/i18n';

type FinanceAdsCategory =
  | 'de_hausrat'
  | 'de_phv'
  | 'de_rechtschutz'
  | 'de_zahn'
  | 'eng_bike'
  | 'eng_dental'
  | 'eng_disability'
  | 'eng_dogliability'
  | 'eng_expathealth'
  | 'eng_household'
  | 'eng_legal'
  | 'eng_life'
  | 'eng_personalliability'
  | 'eng_privatehealth'
  | 'eng_publichealth'
  | 'eng_travelhealth';

const categoryLookup: Record<
  Locale,
  Record<InsuranceTypes, FinanceAdsCategory | undefined>
> = {
  de: {
    BIKE: undefined,
    CAR: undefined,
    COMPANY_HEALTH: undefined,
    COMPANY_LIFE: undefined,
    DENTAL: 'de_zahn',
    DENTAL_V2: 'de_zahn',
    DISABILITY: undefined,
    DOG_LIABILITY: undefined,
    EXPAT_V2: undefined,
    GENERIC: undefined,
    HOUSEHOLD: 'de_hausrat',
    INCOMING: undefined,
    JOB: undefined,
    LEGAL: 'de_rechtschutz',
    LEGAL_V2: 'de_rechtschutz',
    LIABILITY: 'de_phv',
    LIFE: undefined,
    LIFE_V2: undefined,
    PET_HEALTH: undefined,
    PRIVATE_HEALTH: undefined,
    PUBLIC_HEALTH: undefined,
    TRAVEL: undefined,
    PENSION: undefined,
    BASIS_PENSION: undefined,
    INCOMING_ES: undefined,
    TRAVEL_HEALTH_V1: undefined,
    TRAVEL_FR: undefined,
    BIKE_V2: undefined,
    INCOMING_EU: undefined,
    INCOMING_LT: undefined,
    DISABILITY_V2: undefined,
    COMPANY_PENSION: undefined,
    DOG_LIABILITY_ES: undefined,
    BIKE_ES: undefined,
  },
  en: {
    BIKE: 'eng_bike',
    CAR: undefined,
    COMPANY_HEALTH: undefined,
    COMPANY_LIFE: undefined,
    DENTAL: 'eng_dental',
    DENTAL_V2: 'eng_dental',
    DISABILITY: 'eng_disability',
    DOG_LIABILITY: 'eng_dogliability',
    EXPAT_V2: 'eng_expathealth',
    GENERIC: undefined,
    HOUSEHOLD: 'eng_household',
    INCOMING: 'eng_expathealth',
    JOB: undefined,
    LEGAL: 'eng_legal',
    LEGAL_V2: 'eng_legal',
    LIABILITY: 'eng_personalliability',
    LIFE: 'eng_life',
    LIFE_V2: 'eng_life',
    PET_HEALTH: undefined,
    PRIVATE_HEALTH: 'eng_privatehealth',
    PUBLIC_HEALTH: 'eng_publichealth',
    TRAVEL: 'eng_travelhealth',
    PENSION: undefined,
    BASIS_PENSION: undefined,
    INCOMING_ES: undefined,
    TRAVEL_HEALTH_V1: undefined,
    TRAVEL_FR: undefined,
    BIKE_V2: undefined,
    INCOMING_EU: undefined,
    INCOMING_LT: undefined,
    DISABILITY_V2: undefined,
    COMPANY_PENSION: undefined,
    DOG_LIABILITY_ES: undefined,
    BIKE_ES: undefined,
  },
  es: {
    BIKE: undefined,
    CAR: undefined,
    COMPANY_HEALTH: undefined,
    COMPANY_LIFE: undefined,
    DENTAL: undefined,
    DENTAL_V2: undefined,
    DISABILITY: undefined,
    DOG_LIABILITY: undefined,
    EXPAT_V2: undefined,
    GENERIC: undefined,
    HOUSEHOLD: undefined,
    INCOMING: undefined,
    JOB: undefined,
    LEGAL: undefined,
    LEGAL_V2: undefined,
    LIABILITY: undefined,
    LIFE: undefined,
    LIFE_V2: undefined,
    PET_HEALTH: undefined,
    PRIVATE_HEALTH: undefined,
    PUBLIC_HEALTH: undefined,
    TRAVEL: undefined,
    PENSION: undefined,
    BASIS_PENSION: undefined,
    INCOMING_ES: undefined,
    TRAVEL_HEALTH_V1: undefined,
    TRAVEL_FR: undefined,
    BIKE_V2: undefined,
    INCOMING_EU: undefined,
    INCOMING_LT: undefined,
    DISABILITY_V2: undefined,
    COMPANY_PENSION: undefined,
    DOG_LIABILITY_ES: undefined,
    BIKE_ES: undefined,
  },
  fr: {
    BIKE: undefined,
    CAR: undefined,
    COMPANY_HEALTH: undefined,
    COMPANY_LIFE: undefined,
    DENTAL: undefined,
    DENTAL_V2: undefined,
    DISABILITY: undefined,
    DOG_LIABILITY: undefined,
    EXPAT_V2: undefined,
    GENERIC: undefined,
    HOUSEHOLD: undefined,
    INCOMING: undefined,
    JOB: undefined,
    LEGAL: undefined,
    LEGAL_V2: undefined,
    LIABILITY: undefined,
    LIFE: undefined,
    LIFE_V2: undefined,
    PET_HEALTH: undefined,
    PRIVATE_HEALTH: undefined,
    PUBLIC_HEALTH: undefined,
    TRAVEL: undefined,
    PENSION: undefined,
    BASIS_PENSION: undefined,
    INCOMING_ES: undefined,
    TRAVEL_HEALTH_V1: undefined,
    TRAVEL_FR: undefined,
    BIKE_V2: undefined,
    INCOMING_EU: undefined,
    INCOMING_LT: undefined,
    DISABILITY_V2: undefined,
    COMPANY_PENSION: undefined,
    DOG_LIABILITY_ES: undefined,
    BIKE_ES: undefined,
  },
};

export const getFinanceAdsCategory = (verticalId: InsuranceTypes) => {
  const selectedLocale = validateLocale(language());
  return categoryLookup[selectedLocale as Locale][verticalId];
};

export function trackFinanceAdsConversion(
  network: AxiosInstance,
  {
    policyId,
    financeAdsCategory,
  }: { policyId: string; financeAdsCategory: FinanceAdsCategory }
) {
  return network.post('/finance_ads/track_conversion', {
    policyId,
    financeAdsCategory,
  });
}
