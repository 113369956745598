import { useSafeTranslation } from 'shared/i18n';

type Detail = {
  label: string;
  value: string;
};

export interface PolicyDetailsProps {
  productCard: {
    productName: string;
    planName?: string;
    details: Detail[];
  };
  policyDetails: Detail[];
  additionalPolicyDetails?: {
    title: string;
    details: Detail[];
  }[];
}

interface DetailRowProps {
  detail: Detail;
  greyLabel?: boolean;
}

const DetailRow = ({ greyLabel = false, detail }: DetailRowProps) => {
  return (
    <div className="d-flex jc-between mt16 ai-center">
      <p className={`p-p ${greyLabel ? 'tc-grey-600' : ''}`}>{detail.label}</p>
      <p className="p-p">{detail.value}</p>
    </div>
  );
};

export const PolicyDetails = ({
  productCard,
  policyDetails,
  additionalPolicyDetails,
}: PolicyDetailsProps) => {
  const { t } = useSafeTranslation();
  return (
    <div className="wmx7 w100">
      <div className="bg-primary-50 br8 p24">
        <div className="d-flex ai-center mb24">
          <h3 className="p-h3 mr8">{productCard.productName}</h3>
          <h3 className="p-h3 tc-grey-500">{productCard.planName}</h3>
        </div>
        {productCard.details.map((detail) => {
          if (!detail.value) return <></>;
          return <DetailRow detail={detail} />;
        })}
      </div>
      <div className="mt48">
        <h2 className="p-h2">
          {t('paymentScreen.policyDetails.title', 'Policy details')}
        </h2>
        {policyDetails.map((detail) => {
          if (!detail.value) return <></>;
          return <DetailRow detail={detail} greyLabel />;
        })}
        {additionalPolicyDetails?.map((section) => {
          const hasAnyDefinedValue = section.details.some(
            (detail) => !!detail.value
          );
          if (!hasAnyDefinedValue) {
            return <></>;
          }
          return (
            <div className="mt32">
              <h4 className="p-h4">{section.title}</h4>
              {section.details.map((detail) => {
                if (!detail.value) return <></>;
                return <DetailRow detail={detail} greyLabel />;
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
