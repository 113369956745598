import {
  getRoutes,
  removeAnswers,
  RemoveAnswerType,
  Rule,
  stateManagerHelper,
} from '@getpopsure/qnr-framework';
import { ClaimsAction } from 'constants/actions';
import routes from 'constants/routes';
import { PhoneInput } from 'features/legalClaims/components/PhoneInput';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useParams, useRouteMatch } from 'react-router';
import { AppState } from 'reducers';
import { TFunction, useSafeTranslation } from 'shared/i18n';
import {
  SignupQuestionnaire,
  SignupQuestionnaireType,
} from 'SignupQuestionnaire';

import { ClaimSubmitted } from '../legalClaims/components/ClaimSubmitted';
import { Review } from './components/Review';
import { LegalSubmitClaims } from './models';

export type ClaimGroupIds = 'questionnaire';

export const LegalClaimsComponents = {
  PHONE: PhoneInput,
  SUBMITTED: ClaimSubmitted,
  REVIEW: Review,
} as const;

export type ClaimQuestionnaire = SignupQuestionnaireType<
  LegalSubmitClaims,
  ClaimGroupIds,
  typeof LegalClaimsComponents
>;

export const getTranslatedQuestionnaire = (
  t: TFunction
): ClaimQuestionnaire => [
  {
    id: 'description',
    required: true,
    type: 'TEXT',
    props: {
      placeholder: t(
        'legal.qnr.claims.submitClaim.description.placeholder.updated',
        'E.g. my work contract was unjustly terminated after a dispute.'
      ),
      maxLength: 10_000,
    },
    screen: {
      question: t(
        'legal.qnr.claims.submitClaim.description.title',
        'Describe your legal matter'
      ),
      description: t(
        'legal.qnr.claims.submitClaim.description.subtitle',
        'Include details about what happened, when, and who was involved.'
      ),
    },
    groupId: 'questionnaire',
  },
  {
    id: 'phoneNumber',
    required: true,
    type: 'PHONE',
    props: {},
    screen: {
      question: t(
        'legal.qnr.claims.submitClaim.phoneNumber.title',
        "What's your phone number?"
      ),
      description: t(
        'legal.qnr.claims.submitClaim.phoneNumber.subtitle',
        'If more details are needed, the insurance provider will call you.'
      ),
    },
    groupId: 'questionnaire',
  },
  {
    id: 'phoneConsultation',
    required: true,
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: t(
        'legal.qnr.claims.submitClaim.phoneConsultation.title',
        'Have you had a phone consultation about this legal matter?'
      ),
    },
    groupId: 'questionnaire',
  },
  {
    id: 'review',
    required: true,
    type: 'REVIEW',
    props: {
      reviewValues: [
        {
          title: t(
            'legal.qnr.claims.submitClaim.review.description',
            'Case description'
          ),
          value: { type: 'String', key: 'description' },
          path: 'description',
        },
        {
          title: t(
            'legal.qnr.claims.submitClaim.review.phoneNumber',
            'Phone number'
          ),
          value: { type: 'String', key: 'phoneNumber' },
          path: 'phoneNumber',
        },
        {
          title: t(
            'legal.qnr.claims.submitClaim.review.phoneConsultation',
            'Made a phone consultation'
          ),
          value: { type: 'Boolean', key: 'phoneConsultation' },
          path: 'phoneConsultation',
        },
      ],

      confirmationText: t(
        'legal.qnr.claims.submitClaim.review.confirmation',
        'By selecting "Submit claim", I confirm to have answered all questions truthfully. Knowingly omitting any relevant details entitles the insurer to cancel the contract—either retroactively or from the date the omission is discovered—or change the contract in accordance with § 19 Abs. 5 VVG.'
      ),
      ctaText: t('legal.qnr.claims.submitClaim.review.cta', 'Submit claim'),
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'questionnaire',
  },
  {
    id: 'processing',
    required: true,
    type: 'PROCESSING',
    props: {
      textList: [
        t('legal.qnr.claims.processing.loadingText', 'Processing claim...'),
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'questionnaire',
  },
  {
    id: 'submitted',
    required: true,
    type: 'SUBMITTED',
    props: {},
    screen: {
      layout: 'Standalone',
    },
    groupId: 'questionnaire',
  },
];

const rules: Rule<LegalSubmitClaims>[] = [];

const removeProcessing: RemoveAnswerType<LegalSubmitClaims> = {
  op: 'always',
  questions: ['processing', 'submitted'],
};

const removeAnswersLogic: Partial<
  Record<keyof LegalSubmitClaims, RemoveAnswerType<LegalSubmitClaims>>
> = {
  description: removeProcessing,
  phoneConsultation: removeProcessing,
};

export const storeLegalClaimsAnswers = (
  answer: Partial<LegalSubmitClaims>
): ClaimsAction => ({
  type: 'STORE_LEGAL_SUBMIT_CLAIM_V2',
  legalSubmitClaimInfo: answer,
});

export const flushLegalClaimsAnswers = (): ClaimsAction => ({
  type: 'FLUSH_LEGAL_SUBMIT_CLAIM_V2',
});

export const SubmitClaim = () => {
  const questionnaireAnswers =
    useSelector((state: AppState) => state.claims.legalSubmitClaimInfoV2) || {};
  const { policyId } = useParams<{ policyId: string }>();
  const { url } = useRouteMatch();
  const dispatch = useDispatch();

  const { t } = useSafeTranslation();

  const flushAnswers = useCallback(() => {
    dispatch(flushLegalClaimsAnswers());
  }, [dispatch]);

  useEffect(() => {
    return flushAnswers;
  }, [policyId, dispatch, flushAnswers]);

  const onAnswer = <QuestionId extends keyof LegalSubmitClaims>(
    questionId: QuestionId,
    answer: LegalSubmitClaims[QuestionId]
  ) => {
    const answersToRemove = stateManagerHelper(
      removeAnswersLogic,
      questionnaire.components,
      questionnaireAnswers,
      questionnaire.rules
    ).getAnswersToRemove(questionId, answer);

    const nextState = removeAnswers(
      {
        ...questionnaireAnswers,
        [questionId]: answer,
      },
      answersToRemove
    );

    dispatch(storeLegalClaimsAnswers(nextState));
  };

  const questions = getTranslatedQuestionnaire(t);

  const questionnaire = {
    components: questions,
    routes: getRoutes(questions, url),
    rules,
  };

  return (
    <Switch>
      <Route path={routes.claims.legal.submitClaim.questionnaire.path}>
        <SignupQuestionnaire
          questionnaireAnswers={questionnaireAnswers}
          questionnaire={questionnaire}
          onAnswer={onAnswer}
          configuration={{
            components: LegalClaimsComponents,
          }}
          basePath={url}
          questionId="description"
          featureName="LegalClaimSubmitClaim"
        />
      </Route>
    </Switch>
  );
};
