import LoadingSpinner from 'components/loadingSpinner';
import { getCheckoutInfo } from 'features/paymentScreen/paymentScreen.selectors';
import { CheckoutProvider } from 'features/paymentScreenV2/checkout.context';
import PaymentScreenV2 from 'features/paymentScreenV2/containers/PaymentScreenV2/PaymentScreen';
import { useCheckoutRevampFeatureToggle } from 'hooks/useCheckoutRevampFeatureToggle';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import PaymentScreen from '../PaymentScreen/PaymentScreen.container';

const PaymentScreenRedirect = () => {
  const { checkoutId }: { checkoutId: string } = useParams();
  const checkoutInfo = useSelector(getCheckoutInfo(checkoutId));
  const displayNewPaymentScreen = useCheckoutRevampFeatureToggle(
    checkoutInfo?.mainPolicy.policyDetails.type
  );

  if (!displayNewPaymentScreen.flagsReady && checkoutInfo) {
    return <LoadingSpinner />;
  }

  if (checkoutInfo && displayNewPaymentScreen.hasCheckoutRevampFlag) {
    return (
      <CheckoutProvider>
        <PaymentScreenV2 />;
      </CheckoutProvider>
    );
  }

  return <PaymentScreen />;
};

export default PaymentScreenRedirect;
