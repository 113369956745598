import { useSafeTranslation } from 'shared/i18n';
import englishFormattedEuroCurrency from 'shared/util/priceFormatter';

import styles from './style.module.scss';

export interface PriceBreakdownProps {
  productName: string;
  planName?: string;
  basePrice: number;
  priceBreakdown?: {
    label: string;
    value: number;
  }[];
  subTotalPrice: number;

  finalPrice: {
    primaryLabel: string;
    primaryValue: number;
    primaryDescription?: string;

    secondaryLabel: string;
    secondaryValue: number;
    secondaryDescription?: string;
  };
  payComponent: JSX.Element;
  infoBox?: JSX.Element;
}

export const PriceBreakdown = ({
  productName,
  planName,
  basePrice,
  priceBreakdown,
  subTotalPrice,
  finalPrice,
  payComponent,
  infoBox,
}: PriceBreakdownProps) => {
  const { t } = useSafeTranslation();
  return (
    <div className="bg-white br8 wmx7 w100">
      <div className={styles.header}>
        <h5 className="p-p--small tc-primary-500 fw-bold">
          {t('paymentScreen.priceBreakdown.title', 'Price breakdown')}
        </h5>
      </div>
      <div className="p24">
        <div className="d-flex jc-between ai-center mb16">
          <h4 className="p-h4">
            {productName} {planName && planName}
          </h4>
          <p className="p-p">{englishFormattedEuroCurrency(basePrice)}</p>
        </div>
        {priceBreakdown?.map(({ label, value }) => (
          <div className="d-flex jc-between ai-center mb16">
            <h4 className="p-p tc-grey-600">{label}</h4>
            <p className={`p-p ${value < 0 ? 'tc-green-700' : 'tc-gray-900'}`}>
              {englishFormattedEuroCurrency(value)}
            </p>
          </div>
        ))}
        <div className={styles.divider} />
        <div className="d-flex jc-between ai-center mb16">
          <h4 className="p-p">Subtotal</h4>
          <p className="p-p">{englishFormattedEuroCurrency(subTotalPrice)}</p>
        </div>
        <div className={styles.divider} />
        <div className="d-flex jc-between ai-center">
          <div className={styles.finalPriceRow}>
            <p className="p-p mr8">{finalPrice.secondaryLabel}</p>
            <p className="p-p tc-grey-500 mt4">
              {finalPrice.secondaryDescription}
            </p>
          </div>
          <p className="p-p">
            {englishFormattedEuroCurrency(finalPrice.secondaryValue)}
          </p>
        </div>
        <div className="d-flex jc-between ai-center">
          <div className={styles.finalPriceRow}>
            <h4 className="p-h3 mr8">{finalPrice.primaryLabel}</h4>
            <p className="p-p tc-grey-500 mt4">
              {finalPrice.primaryDescription}
            </p>
          </div>
          <p className="p-h2">
            {englishFormattedEuroCurrency(finalPrice.primaryValue)}
          </p>
        </div>
        {infoBox && <div className="mt16">{infoBox}</div>}
        <div className={`${styles.divider} mt16`} />
        {payComponent}
      </div>
    </div>
  );
};
