import { Region } from '@getpopsure/public-models';
import { InsuranceTypes } from 'models/insurances/types';

export const MAX_COVERAGE_RECOMMENDATION_NUM = 3;

export const REGION_INSURANCE_TYPE_MAP: Record<Region, InsuranceTypes[]> = {
  de: [
    'PUBLIC_HEALTH',
    'PRIVATE_HEALTH',
    'INCOMING',
    'TRAVEL',
    'DENTAL',
    'LIABILITY',
    'HOUSEHOLD',
    'BIKE',
    'DOG_LIABILITY',
    'LIFE',
    'DISABILITY',
    'LEGAL',
    'PET_HEALTH',
    'PENSION',
  ],
  fr: ['TRAVEL', 'INCOMING_EU', 'INCOMING_LT', 'HOUSEHOLD', 'LIFE'],
  es: ['INCOMING_ES', 'INCOMING_LT', 'BIKE_ES'],
  fi: ['INCOMING_EU', 'INCOMING_LT'],
  at: ['INCOMING_EU', 'INCOMING_LT'],
  be: ['INCOMING_EU', 'INCOMING_LT'],
  cy: ['INCOMING_EU', 'INCOMING_LT'],
  ee: ['INCOMING_EU', 'INCOMING_LT'],
  gr: ['INCOMING_EU', 'INCOMING_LT'],
  hr: ['INCOMING_EU', 'INCOMING_LT'],
  it: ['INCOMING_EU', 'INCOMING_LT'],
  lt: ['INCOMING_EU', 'INCOMING_LT'],
  lv: ['INCOMING_EU', 'INCOMING_LT'],
  mc: ['INCOMING_EU', 'INCOMING_LT'],
  mt: ['INCOMING_EU', 'INCOMING_LT'],
  nl: ['INCOMING_EU', 'INCOMING_LT'],
  pt: ['INCOMING_EU', 'INCOMING_LT'],
  se: ['INCOMING_EU', 'INCOMING_LT'],
  eu: [],
};
