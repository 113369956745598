import { TFunction } from '@getpopsure/i18n-react';
import { faq, insurance } from '@getpopsure/private-constants';
import {
  getRoutes,
  removeAnswers,
  RemoveAnswerType,
  Rule,
  stateManagerHelper,
} from '@getpopsure/qnr-framework';
import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';
import { ErrorWithAction } from 'components/ErrorWithAction';
import LoadingSpinner from 'components/loadingSpinner';
import { ClaimsAction } from 'constants/actions';
import routes from 'constants/routes';
import { useLoadPolicyDetail } from 'features/expat/hooks';
import { Claim } from 'models/claims';
import { Policy } from 'models/policies';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  generatePath,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router';
import { AppState } from 'reducers';
import { useSafeTranslation } from 'shared/i18n';
import {
  SignupQuestionnaire,
  SignupQuestionnaireType,
} from 'SignupQuestionnaire';

import { ClaimAmountOverThreshold } from './components/ClaimAmountOverThreshold/ClaimAmountOverThreshold';
import { ClaimSubmitted } from './components/ClaimSubmitted/ClaimSubmitted';
import { GettingStarted } from './components/GettingStarted/GettingStarted';
import { PayoutDetails } from './components/PayoutDetails/PayoutDetails';
import { useSubmitExpatLongTermClaim } from './hooks/useSubmitExpatLongTermClaim';
import {
  basicClaimTypeMapping,
  claimTypeMapping,
  ExpatLongTermClaim,
  premiumClaimTypeMapping,
} from './models';

export type ClaimGroupIds = 'questionnaire';

const ExpatClaimComponents = {
  INTRO: GettingStarted,
  PAYOUT_DETAILS: PayoutDetails,
  CLAIM_AMOUNT_OVER_THRESHOLD: ClaimAmountOverThreshold,
  SUBMISSION: ClaimSubmitted,
} as const;

export type ClaimQuestionnaire = SignupQuestionnaireType<
  ExpatLongTermClaim,
  ClaimGroupIds,
  typeof ExpatClaimComponents
>;

export const getTranslatedQuestionnaire = (
  t: TFunction,
  policy: Policy,
  submitClaimRequest: () => Promise<AxiosResponse<Claim>>
): ClaimQuestionnaire => [
  {
    id: 'intro',
    type: 'INTRO',
    groupId: 'questionnaire',
    props: {},
    screen: {
      layout: 'Standalone',
      noMaxWidth: true,
    },
  },
  {
    required: true,
    id: 'claimAmount',
    type: 'CURRENCY',
    props: {
      placeholder: t(
        'claims.expatLongTerm.claimAmount.placeholder',
        'Claim amount'
      ),
    },
    screen: {
      question: t(
        'claims.expatLongTerm.claimAmount.title',
        'What’s the amount for this claim?'
      ),
    },
    groupId: 'questionnaire',
  },
  {
    id: 'claimAmountOverThreshold',
    type: 'CLAIM_AMOUNT_OVER_THRESHOLD',
    props: {},
    screen: {
      layout: 'Standalone',
    },
    groupId: 'questionnaire',
  },
  {
    id: 'completedMedicalQuestionnaire',
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: t(
        'claims.expatLongTerm.completedMedicalQuestionnaire.title',
        'Have you already completed the medical questionnaire?'
      ),
    },
    groupId: 'questionnaire',
  },
  {
    id: 'uploadMedicalQuestionnaire',
    type: 'UPLOAD',
    props: {},
    screen: {
      question: t(
        'claims.expatLongTerm.uploadMedicalQuestionnaire.title',
        'Upload the medical questionnaire'
      ),
      description: t(
        'claims.expatLongTerm.uploadMedicalQuestionnaire.description',
        'Provide only medical questionnaire now, you can upload other documents later.'
      ),
    },
    groupId: 'questionnaire',
  },
  {
    id: 'medicalQuestionnaireBlocker',
    type: 'BLOCKER',
    props: {
      title: t(
        'claims.expatLongTerm.medicalQuestionnaireBlocker.title',
        'Medical questionnaire needs to be completed first'
      ),
      description: t(
        'claims.expatLongTerm.medicalQuestionnaireBlocker.description',
        'Since your claim amount exceeds €500, we need the medical questionnaire filled out by your treating physician to proceed with your claim. \n\nOnce it’s completed, you can return to your Feather account to submit your claim.'
      ),
      buttonProps: [
        {
          type: 'href',
          href: insurance.expatLongTerm.medicalQuestionnaire,
          caption: t(
            'claims.expatLongTerm.medicalQuestionnaireBlocker.buttons.download',
            'Download questionnaire'
          ),
        },
        {
          type: 'href',
          href: faq.expatLongTermCopay,
          variant: 'SECONDARY',
          caption: t(
            'claims.expatLongTerm.medicalQuestionnaireBlocker.buttons.learnMore',
            'Learn more'
          ),
        },
      ],
      iconType: 'SHIELD',
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'questionnaire',
  },
  {
    id: 'claimType',
    required: true,
    type: 'RADIO',
    props: {
      mapValue:
        policy.attributes.planId === 'BASIC'
          ? basicClaimTypeMapping(t)
          : premiumClaimTypeMapping(t),
    },
    screen: {
      question: t(
        'claims.expatLongTerm.claimType.title',
        'What is this claim for?'
      ),
    },
    groupId: 'questionnaire',
  },
  {
    required: true,
    id: 'diagnosis',
    type: 'INPUT',
    props: {
      placeholder: t(
        'claims.expatLongTerm.diagnosis.placeholder',
        'E.g. sore throat, common cold, seasonal allergies'
      ),
    },
    screen: {
      question: t(
        'claims.expatLongTerm.diagnosis.title',
        'What is the diagnosis?'
      ),
    },
    groupId: 'questionnaire',
  },
  {
    required: true,
    id: 'symptomsBegin',
    type: 'INPUT',
    props: {
      placeholder: t(
        'claims.expatLongTerm.symptoms.placeholder',
        'E.g. January 2025'
      ),
    },
    screen: {
      question: t(
        'claims.expatLongTerm.symptoms.title',
        'When did the symptoms first appear?'
      ),
    },
    groupId: 'questionnaire',
  },
  {
    id: 'uploadDocuments',
    type: 'UPLOAD',
    required: true,
    props: {},
    screen: {
      question: t(
        'claims.expatLongTerm.uploadDocuments.title',
        'Upload documents'
      ),
      additionalInfo: {
        title: t(
          'claims.expatLongTerm.uploadDocuments.infoBox.title',
          'What documents should I provide?'
        ),
        description: t(
          'claims.expatLongTerm.uploadDocuments.infoBox.description',
          `1. Itemized invoice(s)\n2. Referral to a specialist or physical therapist\n3. Prescriptions with diagnosis (medications, aids, and remedies)\n4. Proof of payment\n\nWe accept both scanned documents and photos of documents. Make sure that **all pages of the invoice** are included, and the text is readable before submitting.`
        ),
      },
    },
    groupId: 'questionnaire',
  },
  {
    id: 'payoutDetails',
    required: true,
    type: 'PAYOUT_DETAILS',
    groupId: 'questionnaire',
    props: {},
    screen: {
      noMaxWidth: true,
      buttonText: t('claims.expatLongTerm.payoutDetails.button', 'Continue'),
    },
  },
  {
    id: 'review',
    required: true,
    type: 'REVIEW',
    props: {
      /**
       * TODO: [KONG] These fields should not be required (specific to qnrs)
       * Polish sprint material
       */
      requestType: 'CREATE_CLAIM',
      verticalId: 'expatLongTerm',
      insuranceType: 'EXPAT_V2',

      createPostQuote: () => {
        return async () => ({ status: 'SUCCESS' });
      },

      reviewValues: [
        {
          title: t(
            'expatLongTerm.qnr.claims.submitClaim.review.claimAmount',
            'Claim amount'
          ),
          value: { type: 'Currency', key: 'claimAmount' },
          path: 'claimAmount',
        },
        {
          title: t(
            'expatLongTerm.qnr.claims.submitClaim.review.completedMedicalQuestionnaire',
            'Already completed the medical questionnaire?'
          ),
          value: { type: 'Boolean', key: 'completedMedicalQuestionnaire' },
          path: 'completedMedicalQuestionnaire',
        },
        {
          title: t(
            'expatLongTerm.qnr.claims.submitClaim.review.uploadMedicalQuestionnaire',
            'Copy of medical questionnaire'
          ),
          value: { type: 'Upload', key: 'uploadMedicalQuestionnaire' },
          path: 'uploadMedicalQuestionnaire',
        },
        {
          title: t(
            'expatLongTerm.qnr.claims.submitClaim.review.claimType',
            'Claim type'
          ),
          value: {
            type: 'String',
            key: 'claimType',
            valueMap: claimTypeMapping(t),
          },
          path: 'claimType',
        },
        {
          title: t(
            'expatLongTerm.qnr.claims.submitClaim.review.diagnosis',
            'Diagnosis'
          ),
          value: { type: 'String', key: 'diagnosis' },
          path: 'diagnosis',
        },
        {
          title: t(
            'expatLongTerm.qnr.claims.submitClaim.review.symptomsBegin',
            'Symptoms began'
          ),
          value: { type: 'String', key: 'symptomsBegin' },
          path: 'symptomsBegin',
        },
        {
          title: t(
            'expatLongTerm.qnr.claims.submitClaim.review.uploadDocuments',
            'Copy of medical questionnaire'
          ),
          value: { type: 'Upload', key: 'uploadDocuments' },
          path: 'uploadDocuments',
        },
        {
          title: t(
            'expatLongTerm.qnr.claims.submitClaim.review.payoutDetails',
            'EU IBAN'
          ),
          value: { type: 'String', key: 'payoutDetails' },
          path: 'payoutDetails',
        },
      ],

      ctaText: t(
        'expatLongTerm.qnr.claims.submitClaim.review.cta',
        'Submit claim'
      ),
    },

    screen: {
      layout: 'Standalone',
    },
    groupId: 'questionnaire',
  },
  {
    id: 'processing',
    required: true,
    type: 'PROCESSING',
    props: {
      requestFn: submitClaimRequest,
      textList: [
        t(
          'expatLongTerm.qnr.claims.processing.loadingText',
          'Processing claim...'
        ),
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'questionnaire',
  },
  {
    id: 'submitted',
    required: true,
    type: 'SUBMISSION',
    props: {},
    screen: {
      layout: 'Standalone',
    },
    groupId: 'questionnaire',
  },
];

const rules = (): Rule<ExpatLongTermClaim>[] => [
  {
    id: 'claimAmount',
    if: (answer) => Number(answer) > 3500,
    then: {
      goTo: 'claimAmountOverThreshold',
    },
  },
  {
    id: 'claimAmount',
    if: (answer) => Number(answer) >= 500 && Number(answer) <= 3500,
    then: {
      goTo: 'completedMedicalQuestionnaire',
    },
  },
  {
    id: 'completedMedicalQuestionnaire',
    if: (answer) => Boolean(answer),
    then: {
      goTo: 'uploadMedicalQuestionnaire',
    },
    else: {
      goTo: 'medicalQuestionnaireBlocker',
    },
  },
];

const removeProcessing: RemoveAnswerType<ExpatLongTermClaim> = {
  op: 'always',
  questions: ['processing', 'submitted'],
};

const removeAnswersLogic: Partial<
  Record<keyof ExpatLongTermClaim, RemoveAnswerType<ExpatLongTermClaim>>
> = {
  claimAmount: removeProcessing,
  completedMedicalQuestionnaire: removeProcessing,
  uploadMedicalQuestionnaire: removeProcessing,
  claimType: removeProcessing,
  symptomsBegin: removeProcessing,
  uploadDocuments: removeProcessing,
  diagnosis: removeProcessing,
  payoutDetails: removeProcessing,
  review: removeProcessing,
};

export const storeExpatLongTermClaimsAnswers = (
  answer: Partial<ExpatLongTermClaim>
): ClaimsAction => ({
  type: 'STORE_EXPAT_LONG_TERM_CLAIM',
  expatLongTerm: answer,
});

export const flushExpatLongTermClaimsAnswers = (): ClaimsAction => ({
  type: 'FLUSH_EXPAT_LONG_TERM_CLAIM',
});

export const ExpatLongTermClaims = () => {
  const questionnaireAnswers =
    useSelector((state: AppState) => state.claims.expatLongTerm) || {};
  const { policyId } = useParams<{ policyId: string }>();
  const { url } = useRouteMatch();
  const dispatch = useDispatch();

  const { t } = useSafeTranslation();

  const { submitClaimRequest } = useSubmitExpatLongTermClaim(
    questionnaireAnswers,
    policyId
  );

  const history = useHistory();

  const {
    loading: policyLoading,
    policy,
    error,
  } = useLoadPolicyDetail(policyId);

  const flushAnswers = useCallback(() => {
    dispatch(flushExpatLongTermClaimsAnswers());
  }, [dispatch]);

  useEffect(() => {
    return flushAnswers;
  }, [policyId, dispatch, flushAnswers]);

  const handlePolicyError = () => {
    const policyDetailsPage = generatePath(routes.me.policies.path, {
      policyId,
    });
    history.push(policyDetailsPage);
  };

  if (error) {
    Sentry.captureException(
      `[Expat Eu Health] Failed to fetch policy. Claim process not initiated.`
    );
    return (
      <ErrorWithAction
        title={t(
          'claims.expatClaims.startClaimError.title',
          'Something went wrong'
        )}
        description={t(
          'claims.expatClaims.startClaimError.description',
          'Sorry, something didn’t work as it should. Some information needed to submit the claim are missing.'
        )}
        cta={{
          title: t(
            'claims.expatClaims.startClaimError.cta',
            'Go back to your policies'
          ),
          onClick: handlePolicyError,
        }}
      />
    );
  }

  if (policyLoading === true || !policy) {
    return <LoadingSpinner />;
  }

  const onAnswer = <QuestionId extends keyof ExpatLongTermClaim>(
    questionId: QuestionId,
    answer: ExpatLongTermClaim[QuestionId]
  ) => {
    const answersToRemove = stateManagerHelper(
      removeAnswersLogic,
      questionnaire.components,
      questionnaireAnswers,
      questionnaire.rules
    ).getAnswersToRemove(questionId, answer) as (keyof ExpatLongTermClaim)[];

    const nextState = removeAnswers(
      {
        ...questionnaireAnswers,
        [questionId]: answer,
      },
      answersToRemove
    );

    dispatch(storeExpatLongTermClaimsAnswers(nextState));
  };

  const questions = getTranslatedQuestionnaire(t, policy, submitClaimRequest);

  const questionnaire = {
    components: questions,
    routes: getRoutes(questions, url),
    rules: rules(),
  };

  return (
    <Switch>
      <Route path={routes.claims.expatLongTerm.questionnaire.path}>
        <SignupQuestionnaire
          questionnaireAnswers={questionnaireAnswers}
          questionnaire={questionnaire}
          onAnswer={onAnswer}
          configuration={{
            components: ExpatClaimComponents,
          }}
          basePath={url}
          questionId="intro"
          featureName="ExpatLongTermClaim"
        />
      </Route>
    </Switch>
  );
};
