import {
  CreateQuestionnaireType,
  mergeConfiguration,
  PartialQuestionnaire,
  QuestionnaireQuestions,
  QuestionnaireRouter,
  QuestionnaireRouterProps,
} from '@getpopsure/qnr-framework';
import { NavButton } from 'components/NavButton';
import { PolicyShareButton } from 'components/PolicyShareButton/PolicyShareButton';
import { useHistory, useParams } from 'react-router';
import { GenericQuestionnaireState } from 'reducers/genericQuestionnaire';
import { useSafeTranslation } from 'shared/i18n';
import { isMobileApp } from 'shared/util/isMobileApp';
import { isStagingOrDev } from 'shared/util/isStagingOrDev';

import { AutoSuggestMultiSelectForm } from './components/AutoSuggestMultiSelectForm/AutoSuggestMultiSelectForm';
import { CoveredGroup } from './components/CoveredGroup/CoveredGroup';
import { CustomBox } from './components/CustomBox';
import { CustomIntro } from './components/CustomIntro/CustomIntro';
import { Download } from './components/Download';
import { Email } from './components/Email';
import { EmailBasic } from './components/EmailBasic';
import { GenericCheckout } from './components/GenericCheckout';
import { GenericCheckoutV2 } from './components/GenericCheckoutV2/GenericCheckout';
import { MultiNumberInput } from './components/MultiNumberInput';
import { Processing } from './components/Processing';
import { QuotePage } from './components/QuotePage';
import { Review } from './components/Review';
import { ReviewCheckout } from './components/ReviewCheckout';
import { Tally } from './components/Tally/Tally';
import { UploadDocuments } from './components/UploadDocuments';
import styles from './SignupQuestionnaire.module.scss';

export const SignupSpecificComponents = {
  EMAIL: Email,
  QUOTEPAGE: QuotePage,
  REVIEW: Review,
  DOWNLOAD: Download,
  CHECKOUT: ReviewCheckout,
  MULTI_NUMBER_INPUT: MultiNumberInput,
  PROCESSING: Processing,
  UPLOAD: UploadDocuments,
  CUSTOM_INTRO: CustomIntro,
  CUSTOM_BOX: CustomBox,
  EMAIL_BASIC: EmailBasic,
  AUTOSUGGEST_MULTI: AutoSuggestMultiSelectForm,
  GENERIC_CHECKOUT: GenericCheckout,
  GENERIC_CHECKOUT_V2: GenericCheckoutV2,
  COVERED_GROUP: CoveredGroup,
  TALLY: Tally,
} as const;

export type SignupQuestionnaireType<
  Questionnaire extends QuestionnaireQuestions,
  GroupId,
  ExtendedComponentTypes
> = PartialQuestionnaire<
  Questionnaire,
  GroupId,
  typeof SignupSpecificComponents & ExtendedComponentTypes
>;

export type SCREEN = boolean;
export type BLOCKER = boolean;
export type REVIEW = boolean;

export const SignupQuestionnaire: CreateQuestionnaireType<
  Omit<QuestionnaireRouterProps, 'routing'> & {
    share?: {
      verticalId: keyof GenericQuestionnaireState;
    };
  }
  // eslint-disable-next-line react/prop-types
> = ({ configuration = {}, basePath, questionnaireAnswers, ...props }) => {
  const { t } = useSafeTranslation();
  const history = useHistory();
  const { location } = useHistory<{ from?: string }>();
  const { questionId } = useParams<{ questionId: string }>();
  const baseConfiguration = {
    continueButtonText: t('signup.qnr.continueButton.text', 'Continue'),
    components: SignupSpecificComponents,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '',
  };

  // eslint-disable-next-line react/prop-types
  const { share } = props;

  return (
    <>
      {isMobileApp && (
        <div className={styles.hideOnDesktop}>
          <NavButton title="Previous Question" renderInPortal />
        </div>
      )}

      {isStagingOrDev && share && (
        <PolicyShareButton
          questionnaireAnswers={questionnaireAnswers}
          // eslint-disable-next-line react/prop-types
          verticalId={share.verticalId}
        />
      )}

      <QuestionnaireRouter
        {...props}
        questionnaireAnswers={questionnaireAnswers}
        basePath={basePath}
        configuration={mergeConfiguration(baseConfiguration, configuration)}
        routing={{
          path: () => history.location.pathname,
          navigate: (path: string, options) => {
            // Ensure that the current path contains the base path otherwise ignore.
            // The check should prevent unnecessary redirects in case a different part of
            // the application has triggered a url change.
            if (!history.location.pathname.includes(basePath)) {
              return;
            }

            if (options?.replaceState) {
              history.replace(path, location.state || {});
            } else {
              history.push(path, location.state || {});
            }
          },
        }}
        questionId={questionId}
      />
    </>
  );
};
